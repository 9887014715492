export enum AwardName {
  BestFeatureSet2023 = 'BestFeatureSet2023',
  BestMeetsRequirements2024 = 'BestMeetsRequirements2024',
  BestOf2023 = 'BestOf2023',
  BestRelationship2023 = 'BestRelationship2023',
  BestResults2024 = 'BestResults2024',
  BestUsability2024 = 'BestUsability2024',
  BestValueforPrice2023 = 'BestValueforPrice2023',
  BuyersChoice2025 = 'BuyersChoice2025',
  CapterraShortlist2023 = 'CapterraShortlist2023',
  CapterraShortlist2024 = 'CapterraShortlist2024',
  EasiestToUse2024 = 'EasiestToUse2024',
  Fall2024 = 'Fall2024',
  ForbesBestStartupEmployers2024 = 'ForbesBestStartupEmployers2024',
  G21HRProduct = 'G21HRProduct',
  GenericTopRated = 'GenericTopRated',
  HCMSoftware = 'HCMSoftware',
  HRTechAwards2024 = 'HRTechAwards2024',
  LeaderMidMarket2024 = 'LeaderMidMarket2024',
  LeaderSmallBusiness2024 = 'LeaderSmallBusiness2024',
  MomentumLeader2024 = 'MomentumLeader2024',
  MostLoved2024 = 'MostLoved2024',
  SoftwareAdviceFrontRunners2023 = 'SoftwareAdviceFrontRunners2023',
  SoftwareAdviceFrontRunners2024 = 'SoftwareAdviceFrontRunners2024',
  Spring2024 = 'Spring2024',
  SummerCorporateLearningManagementSystemsBestResultsMidMarketTotal2024 = 'SummerCorporateLearningManagementSystemsBestResultsMidMarketTotal2024',
  SummerCorporateLearningManagementSystemsBestResultsTotal2024 = 'SummerCorporateLearningManagementSystemsBestResultsTotal2024',
  SummerCorporateLearningManagementSystemsBestUsabilityMidMarketTotal2024 = 'SummerCorporateLearningManagementSystemsBestUsabilityMidMarketTotal2024',
  SummerG2ApplicantTrackingSystemsATSLeader2024 = 'SummerG2ApplicantTrackingSystemsATSLeader2024',
  SummerG2ApplicantTrackingSystemsATSLeaderMidMarketLeader2024 = 'SummerG2ApplicantTrackingSystemsATSLeaderMidMarketLeader2024',
  SummerG2ApplicantTrackingSystemsATSMomentumLeaderLeader2024 = 'SummerG2ApplicantTrackingSystemsATSMomentumLeaderLeader2024',
  SummerG2BenefitsAdministrationBestUsabilityMidMarketTotal2024 = 'SummerG2BenefitsAdministrationBestUsabilityMidMarketTotal2024',
  SummerG2BenefitsAdministrationBestUsabilityMidMarketTotal12024 = 'SummerG2BenefitsAdministrationBestUsabilityMidMarketTotal12024',
  SummerG2BenefitsAdministrationBestUsabilityMidMarketTotal22024 = 'SummerG2BenefitsAdministrationBestUsabilityMidMarketTotal22024',
  SummerG2BenefitsAdministrationLeaderEnterpriseLeader2024 = 'SummerG2BenefitsAdministrationLeaderEnterpriseLeader2024',
  SummerG2BenefitsAdministrationLeaderEnterpriseLeader12024 = 'SummerG2BenefitsAdministrationLeaderEnterpriseLeader12024',
  SummerG2BenefitsAdministrationLeaderEnterpriseLeader22024 = 'SummerG2BenefitsAdministrationLeaderEnterpriseLeader22024',
  SummerG2BenefitsAdministrationLeaderSmallBusinessLeader2024 = 'SummerG2BenefitsAdministrationLeaderSmallBusinessLeader2024',
  SummerG2BenefitsAdministrationLeaderSmallBusinessLeader12024 = 'SummerG2BenefitsAdministrationLeaderSmallBusinessLeader12024',
  SummerG2BenefitsAdministrationLeaderSmallBusinessLeader22024 = 'SummerG2BenefitsAdministrationLeaderSmallBusinessLeader22024',
  SummerG2BenefitsAdministrationMomentumLeaderLeader2024 = 'SummerG2BenefitsAdministrationMomentumLeaderLeader2024',
  SummerG2BenefitsAdministrationMomentumLeaderLeader12024 = 'SummerG2BenefitsAdministrationMomentumLeaderLeader12024',
  SummerG2BenefitsAdministrationMomentumLeaderLeader22024 = 'SummerG2BenefitsAdministrationMomentumLeaderLeader22024',
  SummerG2ContractorManagementLeaderAmericasLeader2024 = 'SummerG2ContractorManagementLeaderAmericasLeader2024',
  SummerG2ContractorManagementLeaderLeader2024 = 'SummerG2ContractorManagementLeaderLeader2024',
  SummerG2CoreHRHighPerformerEnterpriseHighPerformer2024 = 'SummerG2CoreHRHighPerformerEnterpriseHighPerformer2024',
  SummerG2CoreHRLeaderMidMarketEMEALeader2024 = 'SummerG2CoreHRLeaderMidMarketEMEALeader2024',
  SummerG2CoreHRLeaderMidMarketLeader2024 = 'SummerG2CoreHRLeaderMidMarketLeader2024',
  SummerG2CoreHRLeaderSmallBusinessLeader2024 = 'SummerG2CoreHRLeaderSmallBusinessLeader2024',
  SummerG2CoreHRMomentumLeaderLeader2024 = 'SummerG2CoreHRMomentumLeaderLeader2024',
  SummerG2CorporateLearningManagementSystemsLeaderEMEALeader2024 = 'SummerG2CorporateLearningManagementSystemsLeaderEMEALeader2024',
  SummerG2CorporateLearningManagementSystemsLeaderLeader2024 = 'SummerG2CorporateLearningManagementSystemsLeaderLeader2024',
  SummerG2CorporateLearningManagementSystemsLeaderMidMarketLeader2024 = 'SummerG2CorporateLearningManagementSystemsLeaderMidMarketLeader2024',
  SummerG2CorporateLearningManagementSystemsLeaderSmallBusinessLeader2024 = 'SummerG2CorporateLearningManagementSystemsLeaderSmallBusinessLeader2024',
  SummerG2CorporateLearningManagementSystemsMomentumLeaderLeader2024 = 'SummerG2CorporateLearningManagementSystemsMomentumLeaderLeader2024',
  SummerG2CorporateLearningManagementSystemsMostImplementableTotal2024 = 'SummerG2CorporateLearningManagementSystemsMostImplementableTotal2024',
  SummerG2EmployerofRecordEORBestMeetsRequirementsMidMarketMeetsRequirements2024 = 'SummerG2EmployerofRecordEORBestMeetsRequirementsMidMarketMeetsRequirements2024',
  SummerG2EmployerofRecordEORBestRelationshipMidMarketTotal2024 = 'SummerG2EmployerofRecordEORBestRelationshipMidMarketTotal2024',
  SummerG2EmployerofRecordEORBestResultsMidMarketTotal2024 = 'SummerG2EmployerofRecordEORBestResultsMidMarketTotal2024',
  SummerG2EmployerofRecordEORBestResultsSmallBusinessTotal2024 = 'SummerG2EmployerofRecordEORBestResultsSmallBusinessTotal2024',
  SummerG2EmployerofRecordEORBestResultsTotal2024 = 'SummerG2EmployerofRecordEORBestResultsTotal2024',
  SummerG2EmployerofRecordEORBestUsabilityMidMarketTotal2024 = 'SummerG2EmployerofRecordEORBestUsabilityMidMarketTotal2024',
  SummerG2EmployerofRecordEORBestUsabilitySmallBusinessTotal2024 = 'SummerG2EmployerofRecordEORBestUsabilitySmallBusinessTotal2024',
  SummerG2EmployerofRecordEORBestUsabilitySmallBusinessTotal12024 = 'SummerG2EmployerofRecordEORBestUsabilitySmallBusinessTotal12024',
  SummerG2EmployerofRecordEORBestUsabilityTotal2024 = 'SummerG2EmployerofRecordEORBestUsabilityTotal2024',
  SummerG2EmployerofRecordEOREasiestToUseMidMarketEaseOfUse2024 = 'SummerG2EmployerofRecordEOREasiestToUseMidMarketEaseOfUse2024',
  SummerG2EmployerofRecordEORLeaderMidMarketLeader2024 = 'SummerG2EmployerofRecordEORLeaderMidMarketLeader2024',
  SummerG2EmployerofRecordEORUsersMostLikelyToRecommendMidMarketNps2024 = 'SummerG2EmployerofRecordEORUsersMostLikelyToRecommendMidMarketNps2024',
  SummerG2EndpointManagementEasiestToUseMidMarketEaseOfUse2024 = 'SummerG2EndpointManagementEasiestToUseMidMarketEaseOfUse2024',
  SummerG2EndpointManagementLeaderMidMarketLeader2024 = 'SummerG2EndpointManagementLeaderMidMarketLeader2024',
  SummerG2EndpointManagementMomentumLeaderLeader2024 = 'SummerG2EndpointManagementMomentumLeaderLeader2024',
  SummerG2ExpenseManagementLeaderAmericasLeader2024 = 'SummerG2ExpenseManagementLeaderAmericasLeader2024',
  SummerG2ExpenseManagementLeaderLeader2024 = 'SummerG2ExpenseManagementLeaderLeader2024',
  SummerG2ExpenseManagementLeaderSmallBusinessAmericasLeader2024 = 'SummerG2ExpenseManagementLeaderSmallBusinessAmericasLeader2024',
  SummerG2ExpenseManagementLeaderSmallBusinessAmericasLeader12024 = 'SummerG2ExpenseManagementLeaderSmallBusinessAmericasLeader12024',
  SummerG2ExpenseManagementLeaderSmallBusinessLeader2024 = 'SummerG2ExpenseManagementLeaderSmallBusinessLeader2024',
  SummerG2ExpenseManagementMomentumLeaderLeader2024 = 'SummerG2ExpenseManagementMomentumLeaderLeader2024',
  SummerG2GlobalEmploymentPlatformsGEPBestMeetsRequirementsMeetsRequirements2024 = 'SummerG2GlobalEmploymentPlatformsGEPBestMeetsRequirementsMeetsRequirements2024',
  SummerG2GlobalEmploymentPlatformsGEPEasiestToUseMidMarketEaseOfUse2024 = 'SummerG2GlobalEmploymentPlatformsGEPEasiestToUseMidMarketEaseOfUse2024',
  SummerG2GlobalEmploymentPlatformsGEPHighestUserAdoptionAdoption2024 = 'SummerG2GlobalEmploymentPlatformsGEPHighestUserAdoptionAdoption2024',
  SummerG2GlobalEmploymentPlatformsGEPHighestUserAdoptionMidMarketAdoption2024 = 'SummerG2GlobalEmploymentPlatformsGEPHighestUserAdoptionMidMarketAdoption2024',
  SummerG2GlobalEmploymentPlatformsGEPLeaderLeader2024 = 'SummerG2GlobalEmploymentPlatformsGEPLeaderLeader2024',
  SummerG2GlobalEmploymentPlatformsGEPUsersMostLikelyToRecommendMidMarketNps2024 = 'SummerG2GlobalEmploymentPlatformsGEPUsersMostLikelyToRecommendMidMarketNps2024',
  SummerG2HRAnalyticsBestResultsTotal2024 = 'SummerG2HRAnalyticsBestResultsTotal2024',
  SummerG2HRAnalyticsBestResultsTotal12024 = 'SummerG2HRAnalyticsBestResultsTotal12024',
  SummerG2HRAnalyticsBestUsabilitySmallBusinessTotal2024 = 'SummerG2HRAnalyticsBestUsabilitySmallBusinessTotal2024',
  SummerG2HRAnalyticsBestUsabilityTotal2024 = 'SummerG2HRAnalyticsBestUsabilityTotal2024',
  SummerG2HRAnalyticsLeaderSmallBusinessLeader2024 = 'SummerG2HRAnalyticsLeaderSmallBusinessLeader2024',
  SummerG2HRAnalyticsMomentumLeaderLeader2024 = 'SummerG2HRAnalyticsMomentumLeaderLeader2024',
  SummerG2HRComplianceLeaderLeader2024 = 'SummerG2HRComplianceLeaderLeader2024',
  SummerG2HRComplianceLeaderMidMarketLeader2024 = 'SummerG2HRComplianceLeaderMidMarketLeader2024',
  SummerG2HRComplianceLeaderSmallBusinessLeader2024 = 'SummerG2HRComplianceLeaderSmallBusinessLeader2024',
  SummerG2HRComplianceMomentumLeaderLeader2024 = 'SummerG2HRComplianceMomentumLeaderLeader2024',
  SummerG2HumanResourceManagementSystemsHighPerformerEnterpriseHighPerformer2024 = 'SummerG2HumanResourceManagementSystemsHighPerformerEnterpriseHighPerformer2024',
  SummerG2HumanResourceManagementSystemsLeaderAsiaLeader2024 = 'SummerG2HumanResourceManagementSystemsLeaderAsiaLeader2024',
  SummerG2HumanResourceManagementSystemsLeaderAsiaPacificLeader2024 = 'SummerG2HumanResourceManagementSystemsLeaderAsiaPacificLeader2024',
  SummerG2HumanResourceManagementSystemsLeaderCanadaLeader2024 = 'SummerG2HumanResourceManagementSystemsLeaderCanadaLeader2024',
  SummerG2HumanResourceManagementSystemsLeaderLatinAmericaLeader2024 = 'SummerG2HumanResourceManagementSystemsLeaderLatinAmericaLeader2024',
  SummerG2HumanResourceManagementSystemsLeaderLeader2024 = 'SummerG2HumanResourceManagementSystemsLeaderLeader2024',
  SummerG2HumanResourceManagementSystemsLeaderMidMarketAsiaLeader2024 = 'SummerG2HumanResourceManagementSystemsLeaderMidMarketAsiaLeader2024',
  SummerG2HumanResourceManagementSystemsLeaderMidMarketAsiaPacificLeader2024 = 'SummerG2HumanResourceManagementSystemsLeaderMidMarketAsiaPacificLeader2024',
  SummerG2HumanResourceManagementSystemsLeaderMidMarketLeader2024 = 'SummerG2HumanResourceManagementSystemsLeaderMidMarketLeader2024',
  SummerG2HumanResourceManagementSystemsLeaderSmallBusinessLeader2024 = 'SummerG2HumanResourceManagementSystemsLeaderSmallBusinessLeader2024',
  SummerG2HumanResourceManagementSystemsMomentumLeaderLeader2024 = 'SummerG2HumanResourceManagementSystemsMomentumLeaderLeader2024',
  SummerG2IdentityandAccessManagementIAMHighestUserAdoptionMidMarketAdoption2024 = 'SummerG2IdentityandAccessManagementIAMHighestUserAdoptionMidMarketAdoption2024',
  SummerG2IdentityandAccessManagementIAMHighPerformerMidMarketHighPerformer2024 = 'SummerG2IdentityandAccessManagementIAMHighPerformerMidMarketHighPerformer2024',
  SummerG2IdentityandAccessManagementIAMLeaderAmericasLeader2024 = 'SummerG2IdentityandAccessManagementIAMLeaderAmericasLeader2024',
  SummerG2IdentityandAccessManagementIAMLeaderLeader2024 = 'SummerG2IdentityandAccessManagementIAMLeaderLeader2024',
  SummerG2IdentityandAccessManagementIAMLeaderMidMarketAmericasLeader2024 = 'SummerG2IdentityandAccessManagementIAMLeaderMidMarketAmericasLeader2024',
  SummerG2IdentityandAccessManagementIAMLeaderMidMarketAmericasLeader12024 = 'SummerG2IdentityandAccessManagementIAMLeaderMidMarketAmericasLeader12024',
  SummerG2IdentityandAccessManagementIAMMomentumLeaderLeader2024 = 'SummerG2IdentityandAccessManagementIAMMomentumLeaderLeader2024',
  SummerG2MobileDeviceManagementMDMLeaderMidMarketLeader2024 = 'SummerG2MobileDeviceManagementMDMLeaderMidMarketLeader2024',
  SummerG2MobileDeviceManagementMDMMomentumLeaderLeader2024 = 'SummerG2MobileDeviceManagementMDMMomentumLeaderLeader2024',
  SummerG2MultiCountryPayrollEasiestToUseMidMarketEaseOfUse2024 = 'SummerG2MultiCountryPayrollEasiestToUseMidMarketEaseOfUse2024',
  SummerG2MultiCountryPayrollLeaderAmericasLeader2024 = 'SummerG2MultiCountryPayrollLeaderAmericasLeader2024',
  SummerG2MultiCountryPayrollLeaderLeader2024 = 'SummerG2MultiCountryPayrollLeaderLeader2024',
  SummerG2MultiCountryPayrollLeaderMidMarketAmericasLeader2024 = 'SummerG2MultiCountryPayrollLeaderMidMarketAmericasLeader2024',
  SummerG2MultiCountryPayrollLeaderMidMarketLeader2024 = 'SummerG2MultiCountryPayrollLeaderMidMarketLeader2024',
  SummerG2MultiCountryPayrollMomentumLeaderLeader2024 = 'SummerG2MultiCountryPayrollMomentumLeaderLeader2024',
  SummerG2MultiCountryPayrollUsersMostLikelyToRecommendMidMarketNps2024 = 'SummerG2MultiCountryPayrollUsersMostLikelyToRecommendMidMarketNps2024',
  SummerG2OnboardingLeaderAsiaLeader2024 = 'SummerG2OnboardingLeaderAsiaLeader2024',
  SummerG2OnboardingLeaderAsiaPacificLeader2024 = 'SummerG2OnboardingLeaderAsiaPacificLeader2024',
  SummerG2OnboardingLeaderLeader2024 = 'SummerG2OnboardingLeaderLeader2024',
  SummerG2OnboardingLeaderMidMarketLeader2024 = 'SummerG2OnboardingLeaderMidMarketLeader2024',
  SummerG2OnboardingLeaderSmallBusinessLeader2024 = 'SummerG2OnboardingLeaderSmallBusinessLeader2024',
  SummerG2OnboardingMomentumLeaderLeader2024 = 'SummerG2OnboardingMomentumLeaderLeader2024',
  SummerG2PasswordManagerEasiestToUseMidMarketEaseOfUse2024 = 'SummerG2PasswordManagerEasiestToUseMidMarketEaseOfUse2024',
  SummerG2PasswordManagerHighestUserAdoptionMidMarketAdoption2024 = 'SummerG2PasswordManagerHighestUserAdoptionMidMarketAdoption2024',
  SummerG2PasswordManagerHighestUserAdoptionSmallBusinessAdoption2024 = 'SummerG2PasswordManagerHighestUserAdoptionSmallBusinessAdoption2024',
  SummerG2PasswordManagerLeaderLeader2024 = 'SummerG2PasswordManagerLeaderLeader2024',
  SummerG2PasswordManagerLeaderMidMarketLeader2024 = 'SummerG2PasswordManagerLeaderMidMarketLeader2024',
  SummerG2PasswordManagerMomentumLeaderLeader2024 = 'SummerG2PasswordManagerMomentumLeaderLeader2024',
  SummerG2PayrollBestUsabilityMidMarketTotal2024 = 'SummerG2PayrollBestUsabilityMidMarketTotal2024',
  SummerG2PayrollLeaderAmericasLeader2024 = 'SummerG2PayrollLeaderAmericasLeader2024',
  SummerG2PayrollLeaderAsiaLeader2024 = 'SummerG2PayrollLeaderAsiaLeader2024',
  SummerG2PayrollLeaderAsiaPacificLeader2024 = 'SummerG2PayrollLeaderAsiaPacificLeader2024',
  SummerG2PayrollLeaderCanadaLeader2024 = 'SummerG2PayrollLeaderCanadaLeader2024',
  SummerG2PayrollLeaderLeader2024 = 'SummerG2PayrollLeaderLeader2024',
  SummerG2PayrollLeaderMidMarketAmericasLeader2024 = 'SummerG2PayrollLeaderMidMarketAmericasLeader2024',
  SummerG2PayrollLeaderMidMarketAmericasLeader12024 = 'SummerG2PayrollLeaderMidMarketAmericasLeader12024',
  SummerG2PayrollLeaderMidMarketLeader2024 = 'SummerG2PayrollLeaderMidMarketLeader2024',
  SummerG2PayrollLeaderSmallBusinessAmericasLeader2024 = 'SummerG2PayrollLeaderSmallBusinessAmericasLeader2024',
  SummerG2PayrollLeaderSmallBusinessLeader2024 = 'SummerG2PayrollLeaderSmallBusinessLeader2024',
  SummerG2PayrollMomentumLeaderLeader2024 = 'SummerG2PayrollMomentumLeaderLeader2024',
  SummerG2PEOProvidersBestMeetsRequirementsSmallBusinessMeetsRequirements2024 = 'SummerG2PEOProvidersBestMeetsRequirementsSmallBusinessMeetsRequirements2024',
  SummerG2PEOProvidersBestRelationshipMidMarketTotal2024 = 'SummerG2PEOProvidersBestRelationshipMidMarketTotal2024',
  SummerG2PEOProvidersBestSupportMidMarketQualityOfSupport2024 = 'SummerG2PEOProvidersBestSupportMidMarketQualityOfSupport2024',
  SummerG2PEOProvidersLeaderLeader2024 = 'SummerG2PEOProvidersLeaderLeader2024',
  SummerG2PEOProvidersLeaderMidMarketLeader2024 = 'SummerG2PEOProvidersLeaderMidMarketLeader2024',
  SummerG2PEOProvidersLeaderSmallBusinessLeader2024 = 'SummerG2PEOProvidersLeaderSmallBusinessLeader2024',
  SummerG2PEOProvidersMomentumLeaderLeader2024 = 'SummerG2PEOProvidersMomentumLeaderLeader2024',
  SummerG2SingleSignOnSSOBestRelationshipMidMarketTotal2024 = 'SummerG2SingleSignOnSSOBestRelationshipMidMarketTotal2024',
  SummerG2SingleSignOnSSOBestResultsMidMarketTotal2024 = 'SummerG2SingleSignOnSSOBestResultsMidMarketTotal2024',
  SummerG2SingleSignOnSSOBestResultsTotal2024 = 'SummerG2SingleSignOnSSOBestResultsTotal2024',
  SummerG2SingleSignOnSSOBestUsabilityMidMarketTotal2024 = 'SummerG2SingleSignOnSSOBestUsabilityMidMarketTotal2024',
  SummerG2SingleSignOnSSOLeaderAmericasLeader2024 = 'SummerG2SingleSignOnSSOLeaderAmericasLeader2024',
  SummerG2SingleSignOnSSOLeaderLeader2024 = 'SummerG2SingleSignOnSSOLeaderLeader2024',
  SummerG2SingleSignOnSSOLeaderMidMarketAmericasLeader2024 = 'SummerG2SingleSignOnSSOLeaderMidMarketAmericasLeader2024',
  SummerG2SingleSignOnSSOLeaderMidMarketLeader2024 = 'SummerG2SingleSignOnSSOLeaderMidMarketLeader2024',
  SummerG2SingleSignOnSSOLeaderSmallBusinessAmericasLeader2024 = 'SummerG2SingleSignOnSSOLeaderSmallBusinessAmericasLeader2024',
  SummerG2SingleSignOnSSOLeaderSmallBusinessLeader2024 = 'SummerG2SingleSignOnSSOLeaderSmallBusinessLeader2024',
  SummerG2SingleSignOnSSOMomentumLeaderLeader2024 = 'SummerG2SingleSignOnSSOMomentumLeaderLeader2024',
  SummerG2SpendManagementHighestUserAdoptionAdoption2024 = 'SummerG2SpendManagementHighestUserAdoptionAdoption2024',
  SummerG2SpendManagementHighestUserAdoptionMidMarketAdoption2024 = 'SummerG2SpendManagementHighestUserAdoptionMidMarketAdoption2024',
  SummerG2SpendManagementHighestUserAdoptionSmallBusinessAdoption2024 = 'SummerG2SpendManagementHighestUserAdoptionSmallBusinessAdoption2024',
  SummerG2SummerBenefitsAdministrationBestResultsMidMarketTotal2024 = 'SummerG2SummerBenefitsAdministrationBestResultsMidMarketTotal2024',
  SummerG2SummerBenefitsAdministrationBestResultsMidMarketTotal12024 = 'SummerG2SummerBenefitsAdministrationBestResultsMidMarketTotal12024',
  SummerG2SummerBenefitsAdministrationBestResultsMidMarketTotal22024 = 'SummerG2SummerBenefitsAdministrationBestResultsMidMarketTotal22024',
  SummerG2TimeAttendanceLeaderLeader2024 = 'SummerG2TimeAttendanceLeaderLeader2024',
  SummerG2TimeAttendanceLeaderMidMarketLeader2024 = 'SummerG2TimeAttendanceLeaderMidMarketLeader2024',
  SummerG2TimeAttendanceLeaderSmallBusinessLeader2024 = 'SummerG2TimeAttendanceLeaderSmallBusinessLeader2024',
  SummerG2TimeAttendanceMomentumLeaderLeader2024 = 'SummerG2TimeAttendanceMomentumLeaderLeader2024',
  SummerG2TimeTrackingLeaderLeader2024 = 'SummerG2TimeTrackingLeaderLeader2024',
  SummerG2TimeTrackingLeaderMidMarketLeader2024 = 'SummerG2TimeTrackingLeaderMidMarketLeader2024',
  SummerG2TimeTrackingLeaderSmallBusinessLeader2024 = 'SummerG2TimeTrackingLeaderSmallBusinessLeader2024',
  SummerG2TimeTrackingMomentumLeaderLeader2024 = 'SummerG2TimeTrackingMomentumLeaderLeader2024',
  SummerG2TrainingManagementSystemsLeaderLeader2024 = 'SummerG2TrainingManagementSystemsLeaderLeader2024',
  SummerG2TrainingManagementSystemsLeaderMidMarketLeader2024 = 'SummerG2TrainingManagementSystemsLeaderMidMarketLeader2024',
  SummerG2TrainingManagementSystemsMomentumLeaderLeader2024 = 'SummerG2TrainingManagementSystemsMomentumLeaderLeader2024',
  SummerG2TravelExpenseLeaderLeader2024 = 'SummerG2TravelExpenseLeaderLeader2024',
  SummerG2TravelExpenseLeaderSmallBusinessLeader2024 = 'SummerG2TravelExpenseLeaderSmallBusinessLeader2024',
  SummerG2UserProvisioningandGovernanceToolsBestMeetsRequirementsSmallBusinessMeetsRequirements2024 = 'SummerG2UserProvisioningandGovernanceToolsBestMeetsRequirementsSmallBusinessMeetsRequirements2024',
  SummerG2UserProvisioningandGovernanceToolsLeaderLeader2024 = 'SummerG2UserProvisioningandGovernanceToolsLeaderLeader2024',
  SummerG2UserProvisioningandGovernanceToolsMomentumLeaderLeader2024 = 'SummerG2UserProvisioningandGovernanceToolsMomentumLeaderLeader2024',
  SummerG2WorkforceManagementBestResultsMidMarketTotal2024 = 'SummerG2WorkforceManagementBestResultsMidMarketTotal2024',
  SummerG2WorkforceManagementBestUsabilityMidMarketTotal2024 = 'SummerG2WorkforceManagementBestUsabilityMidMarketTotal2024',
  SummerG2WorkforceManagementBestUsabilitySmallBusinessTotal2024 = 'SummerG2WorkforceManagementBestUsabilitySmallBusinessTotal2024',
  SummerG2WorkforceManagementLeaderLeader2024 = 'SummerG2WorkforceManagementLeaderLeader2024',
  SummerG2WorkforceManagementLeaderMidMarketLeader2024 = 'SummerG2WorkforceManagementLeaderMidMarketLeader2024',
  SummerG2WorkforceManagementLeaderSmallBusinessLeader2024 = 'SummerG2WorkforceManagementLeaderSmallBusinessLeader2024',
  SummerG2WorkforceManagementMomentumLeaderLeader2024 = 'SummerG2WorkforceManagementMomentumLeaderLeader2024',
  SummerG2WorkforcePlanningBestMeetsRequirementsMeetsRequirements2024 = 'SummerG2WorkforcePlanningBestMeetsRequirementsMeetsRequirements2024',
  SummerG2WorkforcePlanningBestMeetsRequirementsMidMarketMeetsRequirements2024 = 'SummerG2WorkforcePlanningBestMeetsRequirementsMidMarketMeetsRequirements2024',
  SummerG2WorkforcePlanningEasiestToUseEaseOfUse2024 = 'SummerG2WorkforcePlanningEasiestToUseEaseOfUse2024',
  SummerG2WorkforcePlanningEasiestToUseMidMarketEaseOfUse2024 = 'SummerG2WorkforcePlanningEasiestToUseMidMarketEaseOfUse2024',
  SummerG2WorkforcePlanningFastestImplementationGoLiveTime2024 = 'SummerG2WorkforcePlanningFastestImplementationGoLiveTime2024',
  SummerG2WorkforcePlanningHighestUserAdoptionAdoption2024 = 'SummerG2WorkforcePlanningHighestUserAdoptionAdoption2024',
  SummerG2WorkforcePlanningLeaderLeader2024 = 'SummerG2WorkforcePlanningLeaderLeader2024',
  SummerG2WorkforcePlanningLeaderMidMarketLeader2024 = 'SummerG2WorkforcePlanningLeaderMidMarketLeader2024',
  Top50HRProducts2024 = 'Top50HRProducts2024',
  Top100SoftwareProducts2024 = 'Top100SoftwareProducts2024',
  TopRated2023 = 'TopRated2023',
  TopRated2024 = 'TopRated2024',
  Winter2025 = 'Winter2025',
  WinterEnterpriseLeader2024 = 'WinterEnterpriseLeader2024'
}
