import { defaultSectionMarginClassName, defaultSectionPaddingClassName } from '../constants';

import type {
  CfComponentFieldsWithMarginAndPadding,
  ComponentWithMargin,
  ComponentWithPadding
} from '../types';

export const defaultEachSide = '0' as const;

export const defaultAllSides = 'default' as const;

export const getPaddingClassNames = ({ attributes }: { attributes: ComponentWithPadding; }) => {
  const {
    applyPaddingToAllSides,
    padding,
    paddingBottom,
    paddingLeft,
    paddingRight,
    paddingTop,
    useDefaultPadding,
  } = attributes;

  /**
   * The value for `useDefaultPadding` and `applyPaddingToAllSides` is not set in FE if true. So we check for false values.
   */
  if (useDefaultPadding === false) {
    if (applyPaddingToAllSides === false) {
      return [
        `pt-${paddingTop ?? defaultEachSide}`,
        `pr-${paddingRight ?? defaultEachSide}`,
        `pb-${paddingBottom ?? defaultEachSide}`,
        `pl-${paddingLeft ?? defaultEachSide}`,
      ];
    }

    return padding === defaultAllSides ? null : [`p-${padding}`];
  }

  return null;
};

export const getMarginClassNames = ({ attributes }: { attributes: ComponentWithMargin; }) => {
  const {
    applyMarginToAllSides,
    margin,
    marginBottom,
    marginLeft,
    marginRight,
    marginTop,
    useDefaultMargin,
  } = attributes;

  if (useDefaultMargin === false) {
    if (applyMarginToAllSides === false) {
      return [
        `mt-${marginTop ?? defaultEachSide}`,
        `mr-${marginRight ?? defaultEachSide}`,
        `mb-${marginBottom ?? defaultEachSide}`,
        `ml-${marginLeft ?? defaultEachSide}`,
      ];
    }

    return margin === defaultAllSides ? null : [`m-${margin}`];
  }

  return null;
};

type PaddingFields = Pick<CfComponentFieldsWithMarginAndPadding, 'paddingBottom' | 'paddingTop'>;

export const getCfPaddingClassNames = (fields: PaddingFields, opts?: { defaultValue?: string }) => {
  const { paddingBottom, paddingTop } = fields;
  const { defaultValue = defaultSectionPaddingClassName } = opts ?? {};

  if (!paddingBottom && !paddingTop) {
    return defaultValue;
  }

  return [paddingTop && `pt-${paddingTop.fields.data.value}`, paddingBottom && `pb-${paddingBottom.fields.data.value}`];
};

type MarginFields = Pick<CfComponentFieldsWithMarginAndPadding, 'marginBottom' | 'marginTop'>;

export const getCfMarginClassNames = (fields: MarginFields, opts?: { defaultValue?: string }) => {
  const { marginBottom, marginTop } = fields;
  const { defaultValue = defaultSectionMarginClassName } = opts ?? {};

  if (!marginBottom && !marginTop) {
    return defaultValue;
  }

  return [marginTop && `mt-${marginTop.fields.data.value}`, marginBottom && `mb-${marginBottom.fields.data.value}`];
};
