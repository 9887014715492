import {
  memo, ReactNode, useMemo
} from 'react';

import NextHead from 'next/head';

import { replaceUnicodes } from ':lib/_i18n';

import { LOCALES } from '../locales';

const fallbackImage = 'https://rippling.imgix.net/images/PreviewImage_Product.png?fm=png';
const DEPLOYMENT_URI = process.env.NEXT_PUBLIC_DOMAIN_ORIGIN || 'https://www.rippling.com';

export interface LocalePath {
  locale: string;
  path: string;
}

export interface HeadProps {
  description: string;
  isIndexed: boolean;
  locale: string;
  path: string;
  title: string;
  children?: ReactNode;
  image?: string;
  publishedLocalePaths?: LocalePath[];
  styles?: {
    criticalCSS?: string,
    missingAtomicCSS?: string
  }
}

const useHrefLang = ({
  locale: pageLocale,
  path,
  publishedLocalePaths,
}: Pick<HeadProps, 'locale' | 'path' | 'publishedLocalePaths'>) => {
  const {
    currentPage, defaultPage, hreflangs,
  } = useMemo(() => {
    const publishedPageLocale = publishedLocalePaths.find(({ locale }) => {
      return pageLocale === locale;
    });

    if (publishedPageLocale) {
      // To determine the x-default
      const getDefaultPage = () => {
        if (publishedLocalePaths.length === 0) {
          return null;
        }

        // US as fallback
        const defaultUs = publishedLocalePaths.find(({ locale }) => {
          return locale === LOCALES.EN_US;
        });

        if (defaultUs) {
          return defaultUs;
        }

        // Whatever the first one in the list
        return publishedLocalePaths[0] ?? null;
      };

      return {
        currentPage: publishedPageLocale,
        defaultPage: getDefaultPage(),
        hreflangs: publishedLocalePaths,
      };
    }

    // If not published, just self reference
    const localePrefix = pageLocale === LOCALES.EN_US ? '' : `/${pageLocale}`;
    const localePath = `${localePrefix}${path}`;

    const currentPage = {
      locale: pageLocale,
      path: localePath,
    };

    return {
      currentPage,
      defaultPage: currentPage,
      hreflangs: [currentPage],
    };
  }, [pageLocale, path]);

  return {
    currentUrl: `${DEPLOYMENT_URI}${currentPage.path}`,
    defaultPageUrl: `${DEPLOYMENT_URI}${defaultPage.path}`,
    hreflangs: hreflangs ?? [],
  };
};

const RobotsMeta = ({ isIndexed }: Pick<HeadProps, 'isIndexed'>) => {
  const robotsIndexContent = isIndexed ? 'index, follow' : 'noindex, nofollow';
  const botsIndexContent = `${robotsIndexContent}, max-snippet:-1, max-image-preview:large, max-video-preview:-1`;

  return (
    <>
      <meta content={robotsIndexContent} name="robots" />
      <meta content={botsIndexContent} name="googlebot" />
      <meta content={botsIndexContent} name="bingbot" />
    </>
  );
};

const SocialMeta = ({
  description, image, locale, title, url,
}: { url: string } & Pick<HeadProps, 'description' | 'image' | 'locale' | 'title'>) => {
  // these should be lang_REGION (lowercase + _underscore_ + UPPER)
  const ogLocale = locale.replace(/-/, '_');

  const useImage = image || fallbackImage;

  return (
    <>
      {/* Open Graph */}
      <meta content={ogLocale} property="og:locale" />
      <meta content="website" property="og:type" />
      <meta content={title} property="og:title" />
      {!!description && <meta content={description} property="og:description" />}
      {!!url && <meta content={url} property="og:url" />}
      <meta content="Rippling" property="og:site_name" />
      <meta content={useImage} property="og:image" />
      <meta content="940" property="og:image:width" />
      <meta content="490" property="og:image:height" />
      {/* Twitter */}
      <meta content="summary_large_image" name="twitter:card" />
      <meta content={useImage} name="twitter:image" />
      <meta content="@ripplingapp" name="twitter:creator" />
      <meta content="@ripplingapp" name="twitter:site" />
    </>
  );
};

const HrefLangMeta = ({
  defaultPageUrl, hreflangs, url,
}: {
  defaultPageUrl: string;
  hreflangs: LocalePath[];
  url: string;
}) => {
  return (
    <>
      <link href={url} rel="canonical" />
      <link
        href={defaultPageUrl}
        hrefLang="x-default"
        rel="alternate"
      />
      {hreflangs.map(({ locale, path }) => {
        return (
          <link
            key={`link-locales-${locale}`}
            href={`${DEPLOYMENT_URI}${path}`}
            hrefLang={locale.toLowerCase()}
            rel="alternate"
          />
        );
      })}
    </>
  );
};

const Optimizely = () => {
  const optimizelyCdnUrl = process.env.NEXT_PUBLIC_OPTIMIZELY_CDN_URL;
  const optimizelyProjectId = process.env.NEXT_PUBLIC_OPTIMIZELY_PROJECT_ID;
  const optimizelyScriptUrl = `${optimizelyCdnUrl}/js/${optimizelyProjectId}.js`;
  const shouldLoadOptimizely = !!(optimizelyCdnUrl && optimizelyProjectId);

  if (!shouldLoadOptimizely) {
    return null;
  }

  return (
    <>
      <link href={optimizelyCdnUrl} rel="preconnect" />
      <link
        as="script"
        href={optimizelyScriptUrl}
        rel="preload"
      />
      <script src={optimizelyScriptUrl} />
    </>
  );
};

const Head = ({
  children,
  image,
  isIndexed,
  locale,
  path,
  publishedLocalePaths = [],
  styles,
  ...props
}: HeadProps) => {
  const { description, title } = props;
  const { criticalCSS, missingAtomicCSS } = styles ?? {};
  const {
    currentUrl, defaultPageUrl, hreflangs,
  } = useHrefLang({
    locale,
    path,
    publishedLocalePaths,
  });

  return (
    <NextHead>
      <title>{replaceUnicodes(title)}</title>
      <meta content={description} name="description" />
      <RobotsMeta isIndexed={isIndexed} />
      <HrefLangMeta
        defaultPageUrl={defaultPageUrl}
        hreflangs={hreflangs}
        url={currentUrl}
      />
      <SocialMeta
        description={description}
        image={image}
        locale={locale}
        title={title}
        url={currentUrl}
      />
      {!!criticalCSS && <style dangerouslySetInnerHTML={{ __html: `${criticalCSS}` }} />}
      {!!missingAtomicCSS && <style dangerouslySetInnerHTML={{ __html: `${missingAtomicCSS}` }} />}
      <Optimizely />
      {/* Used to fix "rogue referrer problem" https://www.simoahava.com/gtm-tips/fix-rogue-referral-problem-single-page-sites/ */}
      <script
        dangerouslySetInnerHTML={{
          __html: `window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              originalLocation: document.location.protocol + '//' +
              document.location.hostname +
              document.location.pathname +
              document.location.search
            });`,
        }}
      />
      {/* <!-- Qualified (Start) --> */}
      {process.env.NEXT_PUBLIC_ENABLE_QUALIFIED === 'true' && (
        <>
          <script
            dangerouslySetInnerHTML={{
              __html: `
            (function(w,q){w['QualifiedObject']=q;w[q]=w[q]||function(){
            (w[q].q=w[q].q||[]).push(arguments)};})(window,'qualified')`,
            }}
          />
          <script
            async
            src="https://js.qualified.com/qualified.js?token=xiZzNAGkA8hE9UwP"
          />
        </>
      )}
      {/* <!-- Qualified (End) --> */}
      {children}
    </NextHead>
  );
};

export default memo(Head);
