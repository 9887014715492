import React from 'react';

import { normalizeUrlPath } from ':helpers/string-helpers';

import { LOCALES } from '../locales';
import Head from './Head';

export interface RgbHeadComponentProps {
  head: {
    mode?: {
      isDevelopment?: boolean;
      locale?: string;
      pageLocale?: string;
      pageLocaleDefaulted?: string;
    };
    seo?: {
      _canonicalUrl?: string;
      _isIndexed?: boolean;
      description?: string;
      image?: string;
      title?: string;
      uri?: string;
    }
    styles?: {
      criticalCSS?: string;
      missingAtomicCSS?: string;
    }
  }
  alternativePages?: {
    locale: string;
    path: string;
  }[];
}

const getIsI18nNotAvail = ({ pageLocale, pageLocaleDefaulted }: RgbHeadComponentProps['head']['mode']) => {
  if (!pageLocale) {
    return false;
  }

  return pageLocaleDefaulted;
};

const HeadComponent = ({
  alternativePages,
  head,
}: RgbHeadComponentProps) => {
  const {
    mode = {}, seo = {}, styles = {},
  } = head;
  const {
    _canonicalUrl, _isIndexed, description, image, title, uri,
  } = seo;
  const { isDevelopment, locale: pageLocale = LOCALES.EN_US } = mode;

  const shouldIndex = _isIndexed && !isDevelopment;
  const isI18nNotAvail = getIsI18nNotAvail(mode);
  const sanitizedCanonical = normalizeUrlPath(_canonicalUrl);

  return (
    <Head
      description={description}
      image={image}
      isIndexed={shouldIndex && !isI18nNotAvail}
      locale={pageLocale}
      path={sanitizedCanonical || uri}
      publishedLocalePaths={alternativePages}
      styles={styles}
      title={title}
    >
      <link
        href="/css/paradigms.css"
        rel="stylesheet"
      />
      <link
        href="/css/atomic-purged.css"
        rel="stylesheet"
      />
      {typeof window !== 'undefined' && window.location.pathname === '/interactive-tour' && (
        <>
          <script src="https://js.storylane.io/js/v1/player_min.js" />
          <script
            dangerouslySetInnerHTML={{
              __html: `
            function launchStorylaneDemo(){
              new StorylaneEmbed(
                { dom_id: 'storylane-demo-section', // id of the dom element
                  demo_url: 'https://app.storylane.io/demo/ynabww7wzqb1?embed=true'
                });
            }
          `,
            }}
          />
        </>
      )}
    </Head>
  );
};

export default HeadComponent;
