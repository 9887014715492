export enum LogoName {
  AgilityRoboticsLargeDefault = 'AgilityRoboticsLargeDefault',
  AgilityRoboticsLargeGrey = 'AgilityRoboticsLargeGrey',
  AgilityRoboticsLargeHighlight = 'AgilityRoboticsLargeHighlight',
  AgilityRoboticsSmallDefault = 'AgilityRoboticsSmallDefault',
  AgilityRoboticsSmallGrey = 'AgilityRoboticsSmallGrey',
  AgilityRoboticsSmallHighlight = 'AgilityRoboticsSmallHighlight',
  AndrosIconDefault = 'AndrosIconDefault',
  AndrosLargeDefault = 'AndrosLargeDefault',
  AndrosLargeGrey = 'AndrosLargeGrey',
  AndrosLargeHighlight = 'AndrosLargeHighlight',
  AndrosSmallDefault = 'AndrosSmallDefault',
  AndrosSmallGrey = 'AndrosSmallGrey',
  AndrosSmallHighlight = 'AndrosSmallHighlight',
  AndrosSmallHighlightU1 = 'AndrosSmallHighlightU1',
  AnthropicLargeDefault = 'AnthropicLargeDefault',
  AnthropicLargeGrey = 'AnthropicLargeGrey',
  AnthropicLargeHighlight = 'AnthropicLargeHighlight',
  AnthropicSmallDefault = 'AnthropicSmallDefault',
  AnthropicSmallGrey = 'AnthropicSmallGrey',
  AnthropicSmallHighlight = 'AnthropicSmallHighlight',
  AppcuesIconDefault = 'AppcuesIconDefault',
  AppcuesLargeDefault = 'AppcuesLargeDefault',
  AppcuesLargeGrey = 'AppcuesLargeGrey',
  AppcuesLargeHighlight = 'AppcuesLargeHighlight',
  AppcuesSmallDefault = 'AppcuesSmallDefault',
  AppcuesSmallGrey = 'AppcuesSmallGrey',
  AppcuesSmallHighlight = 'AppcuesSmallHighlight',
  ArrowElectricLargeDefault = 'ArrowElectricLargeDefault',
  ArrowElectricLargeGrey = 'ArrowElectricLargeGrey',
  ArrowElectricLargeHighlight = 'ArrowElectricLargeHighlight',
  ArrowElectricSmallDefault = 'ArrowElectricSmallDefault',
  ArrowElectricSmallGrey = 'ArrowElectricSmallGrey',
  ArrowElectricSmallHighlight = 'ArrowElectricSmallHighlight',
  AssemblyIconDefault = 'AssemblyIconDefault',
  AssemblyLargeDefault = 'AssemblyLargeDefault',
  AssemblyLargeGrey = 'AssemblyLargeGrey',
  AssemblyLargeHighlight = 'AssemblyLargeHighlight',
  AssemblySmallDefault = 'AssemblySmallDefault',
  AssemblySmallGrey = 'AssemblySmallGrey',
  AssemblySmallHighlight = 'AssemblySmallHighlight',
  AssuranceLabLargeDefault = 'AssuranceLabLargeDefault',
  AssuranceLabLargeGrey = 'AssuranceLabLargeGrey',
  AssuranceLabLargeHighlight = 'AssuranceLabLargeHighlight',
  AssuranceLabSmallDefault = 'AssuranceLabSmallDefault',
  AssuranceLabSmallGrey = 'AssuranceLabSmallGrey',
  AssuranceLabSmallHighlight = 'AssuranceLabSmallHighlight',
  AvidonHealthLargeDefault = 'AvidonHealthLargeDefault',
  AvidonHealthLargeGrey = 'AvidonHealthLargeGrey',
  AvidonHealthLargeHighlight = 'AvidonHealthLargeHighlight',
  AvidonHealthSmallDefault = 'AvidonHealthSmallDefault',
  AvidonHealthSmallGrey = 'AvidonHealthSmallGrey',
  AvidonHealthSmallHighlight = 'AvidonHealthSmallHighlight',
  BanzaLargeDefault = 'BanzaLargeDefault',
  BanzaLargeGrey = 'BanzaLargeGrey',
  BanzaLargeHighlight = 'BanzaLargeHighlight',
  BanzaSmallDefault = 'BanzaSmallDefault',
  BanzaSmallGrey = 'BanzaSmallGrey',
  BanzaSmallHighlight = 'BanzaSmallHighlight',
  BarrysBootcampLargeDefault = 'BarrysBootcampLargeDefault',
  BarrysBootcampLargeGrey = 'BarrysBootcampLargeGrey',
  BarrysBootcampLargeHighlight = 'BarrysBootcampLargeHighlight',
  BarrysBootcampSmallDefault = 'BarrysBootcampSmallDefault',
  BarrysBootcampSmallGrey = 'BarrysBootcampSmallGrey',
  BarrysBootcampSmallHighlight = 'BarrysBootcampSmallHighlight',
  BeamLargeDefault = 'BeamLargeDefault',
  BeamLargeGrey = 'BeamLargeGrey',
  BeamLargeHighlight = 'BeamLargeHighlight',
  BeamSmallDefault = 'BeamSmallDefault',
  BeamSmallGrey = 'BeamSmallGrey',
  BeamSmallHighlight = 'BeamSmallHighlight',
  BellhopLargeDefault = 'BellhopLargeDefault',
  BellhopLargeGrey = 'BellhopLargeGrey',
  BellhopLargeGreyU1 = 'BellhopLargeGreyU1',
  BellhopLargeHighlight = 'BellhopLargeHighlight',
  BellhopSmallDefault = 'BellhopSmallDefault',
  BellhopSmallGrey = 'BellhopSmallGrey',
  BellhopSmallHighlight = 'BellhopSmallHighlight',
  BennieLargeDefault = 'BennieLargeDefault',
  BennieLargeGrey = 'BennieLargeGrey',
  BennieLargeHighlight = 'BennieLargeHighlight',
  BennieSmallDefault = 'BennieSmallDefault',
  BennieSmallGrey = 'BennieSmallGrey',
  BennieSmallHighlight = 'BennieSmallHighlight',
  BlankStreetLargeDefault = 'BlankStreetLargeDefault',
  BlankStreetLargeGrey = 'BlankStreetLargeGrey',
  BlankStreetLargeHighlight = 'BlankStreetLargeHighlight',
  BlankStreetSmallDefault = 'BlankStreetSmallDefault',
  BlankStreetSmallGrey = 'BlankStreetSmallGrey',
  BlankStreetSmallHighlight = 'BlankStreetSmallHighlight',
  BlazePizzaLargeDefault = 'BlazePizzaLargeDefault',
  BlazePizzaLargeGrey = 'BlazePizzaLargeGrey',
  BlazePizzaLargeGreyU1 = 'BlazePizzaLargeGreyU1',
  BlazePizzaLargeHighlight = 'BlazePizzaLargeHighlight',
  BlazePizzaSmallDefault = 'BlazePizzaSmallDefault',
  BlazePizzaSmallGrey = 'BlazePizzaSmallGrey',
  BlazePizzaSmallHighlight = 'BlazePizzaSmallHighlight',
  BlockchainIconDefault = 'BlockchainIconDefault',
  BlockchainLargeDefault = 'BlockchainLargeDefault',
  BlockchainLargeGrey = 'BlockchainLargeGrey',
  BlockchainLargeHighlight = 'BlockchainLargeHighlight',
  BlockchainSmallDefault = 'BlockchainSmallDefault',
  BlockchainSmallGrey = 'BlockchainSmallGrey',
  BlockchainSmallHighlight = 'BlockchainSmallHighlight',
  BrightsideHealthLargeDefault = 'BrightsideHealthLargeDefault',
  BrightsideHealthLargeGrey = 'BrightsideHealthLargeGrey',
  BrightsideHealthLargeGreyU1 = 'BrightsideHealthLargeGreyU1',
  BrightsideHealthLargeHighlight = 'BrightsideHealthLargeHighlight',
  BrightsideHealthSmallDefault = 'BrightsideHealthSmallDefault',
  BrightsideHealthSmallGrey = 'BrightsideHealthSmallGrey',
  BrightsideHealthSmallHighlight = 'BrightsideHealthSmallHighlight',
  BrightweelLargeDefault = 'BrightweelLargeDefault',
  BrightweelLargeGrey = 'BrightweelLargeGrey',
  BrightweelLargeHighlight = 'BrightweelLargeHighlight',
  BrightweelSmallDefault = 'BrightweelSmallDefault',
  BrightweelSmallGrey = 'BrightweelSmallGrey',
  BrightweelSmallHighlight = 'BrightweelSmallHighlight',
  BTCLargeDefault = 'BTCLargeDefault',
  BTCLargeGrey = 'BTCLargeGrey',
  BTCLargeHighlight = 'BTCLargeHighlight',
  BTCSmallDefault = 'BTCSmallDefault',
  BTCSmallGrey = 'BTCSmallGrey',
  BTCSmallHighlight = 'BTCSmallHighlight',
  ChesscomIconDefault = 'ChesscomIconDefault',
  ChesscomLargeDefault = 'ChesscomLargeDefault',
  ChesscomLargeGrey = 'ChesscomLargeGrey',
  ChesscomLargeHighlight = 'ChesscomLargeHighlight',
  ChesscomSmallDefault = 'ChesscomSmallDefault',
  ChesscomSmallGrey = 'ChesscomSmallGrey',
  ChesscomSmallHighlight = 'ChesscomSmallHighlight',
  ClickleaseLargeDefault = 'ClickleaseLargeDefault',
  ClickleaseLargeGrey = 'ClickleaseLargeGrey',
  ClickleaseLargeHighlight = 'ClickleaseLargeHighlight',
  ClickleaseSmallDefault = 'ClickleaseSmallDefault',
  ClickleaseSmallGrey = 'ClickleaseSmallGrey',
  ClickleaseSmallHighlight = 'ClickleaseSmallHighlight',
  CobexConstructionGroupIconDefault = 'CobexConstructionGroupIconDefault',
  CobexConstructionGroupLargeDefault = 'CobexConstructionGroupLargeDefault',
  CobexConstructionGroupLargeGrey = 'CobexConstructionGroupLargeGrey',
  CobexConstructionGroupLargeHighlight = 'CobexConstructionGroupLargeHighlight',
  CobexConstructionGroupSmallDefault = 'CobexConstructionGroupSmallDefault',
  CobexConstructionGroupSmallGrey = 'CobexConstructionGroupSmallGrey',
  CobexConstructionGroupSmallHighlight = 'CobexConstructionGroupSmallHighlight',
  CoffeeMeetsBagelIconDefault = 'CoffeeMeetsBagelIconDefault',
  CoffeeMeetsBagelLargeDefault = 'CoffeeMeetsBagelLargeDefault',
  CoffeeMeetsBagelLargeGrey = 'CoffeeMeetsBagelLargeGrey',
  CoffeeMeetsBagelLargeHighlight = 'CoffeeMeetsBagelLargeHighlight',
  CoffeeMeetsBagelSmallDefault = 'CoffeeMeetsBagelSmallDefault',
  CoffeeMeetsBagelSmallGrey = 'CoffeeMeetsBagelSmallGrey',
  CoffeeMeetsBagelSmallHighlight = 'CoffeeMeetsBagelSmallHighlight',
  CompassCoffeeLargeDefault = 'CompassCoffeeLargeDefault',
  CompassCoffeeLargeGrey = 'CompassCoffeeLargeGrey',
  CompassCoffeeLargeHighlight = 'CompassCoffeeLargeHighlight',
  CompassCoffeeSmallDefault = 'CompassCoffeeSmallDefault',
  CompassCoffeeSmallGrey = 'CompassCoffeeSmallGrey',
  CompassCoffeeSmallHighlight = 'CompassCoffeeSmallHighlight',
  CrateLargeDefault = 'CrateLargeDefault',
  CrateLargeGrey = 'CrateLargeGrey',
  CrateLargeHighlight = 'CrateLargeHighlight',
  CrateSmallDefault = 'CrateSmallDefault',
  CrateSmallGrey = 'CrateSmallGrey',
  CrateSmallHighlight = 'CrateSmallHighlight',
  CrumblLargeDefault = 'CrumblLargeDefault',
  CrumblLargeGrey = 'CrumblLargeGrey',
  CrumblLargeHighlight = 'CrumblLargeHighlight',
  CrumblSmallDefault = 'CrumblSmallDefault',
  CrumblSmallGrey = 'CrumblSmallGrey',
  CrumblSmallHighlight = 'CrumblSmallHighlight',
  DecathlonLargeDefault = 'DecathlonLargeDefault',
  DecathlonLargeGrey = 'DecathlonLargeGrey',
  DecathlonLargeHighlight = 'DecathlonLargeHighlight',
  DecathlonSmallDefault = 'DecathlonSmallDefault',
  DecathlonSmallGrey = 'DecathlonSmallGrey',
  DecathlonSmallHighlight = 'DecathlonSmallHighlight',
  DoubleGoodLargeDefault = 'DoubleGoodLargeDefault',
  DoubleGoodLargeGrey = 'DoubleGoodLargeGrey',
  DoubleGoodLargeHighlight = 'DoubleGoodLargeHighlight',
  DoubleGoodSmallDefault = 'DoubleGoodSmallDefault',
  DoubleGoodSmallGrey = 'DoubleGoodSmallGrey',
  DoubleGoodSmallHighlight = 'DoubleGoodSmallHighlight',
  EdgeDeltaLargeDefault = 'EdgeDeltaLargeDefault',
  EdgeDeltaLargeGrey = 'EdgeDeltaLargeGrey',
  EdgeDeltaLargeHighlight = 'EdgeDeltaLargeHighlight',
  EdgeDeltaSmallDefault = 'EdgeDeltaSmallDefault',
  EdgeDeltaSmallGrey = 'EdgeDeltaSmallGrey',
  EdgeDeltaSmallHighlight = 'EdgeDeltaSmallHighlight',
  EmotiveLargeDefault = 'EmotiveLargeDefault',
  EmotiveLargeGrey = 'EmotiveLargeGrey',
  EmotiveLargeHighlight = 'EmotiveLargeHighlight',
  EmotiveSmallDefault = 'EmotiveSmallDefault',
  EmotiveSmallGrey = 'EmotiveSmallGrey',
  EmotiveSmallHighlight = 'EmotiveSmallHighlight',
  EncampLargeDefault = 'EncampLargeDefault',
  EncampLargeGrey = 'EncampLargeGrey',
  EncampLargeHighlight = 'EncampLargeHighlight',
  EncampSmallDefault = 'EncampSmallDefault',
  EncampSmallGrey = 'EncampSmallGrey',
  EncampSmallHighlight = 'EncampSmallHighlight',
  EnterpretLargeDefault = 'EnterpretLargeDefault',
  EnterpretLargeGrey = 'EnterpretLargeGrey',
  EnterpretLargeHighlight = 'EnterpretLargeHighlight',
  EnterpretSmallDefault = 'EnterpretSmallDefault',
  EnterpretSmallGrey = 'EnterpretSmallGrey',
  EnterpretSmallHighlight = 'EnterpretSmallHighlight',
  EnvedaBiosciencesLargeDefault = 'EnvedaBiosciencesLargeDefault',
  EnvedaBiosciencesLargeGrey = 'EnvedaBiosciencesLargeGrey',
  EnvedaBiosciencesLargeHighlight = 'EnvedaBiosciencesLargeHighlight',
  EnvedaBiosciencesSmallDefault = 'EnvedaBiosciencesSmallDefault',
  EnvedaBiosciencesSmallGrey = 'EnvedaBiosciencesSmallGrey',
  EnvedaBiosciencesSmallHighlight = 'EnvedaBiosciencesSmallHighlight',
  EvenUpLargeDefault = 'EvenUpLargeDefault',
  EvenUpLargeGrey = 'EvenUpLargeGrey',
  EvenUpLargeHighlight = 'EvenUpLargeHighlight',
  EvenUpLargeHighlightU1 = 'EvenUpLargeHighlightU1',
  EvenUpSmallDefault = 'EvenUpSmallDefault',
  EvenUpSmallGrey = 'EvenUpSmallGrey',
  EvenUpSmallHighlight = 'EvenUpSmallHighlight',
  FahertyLargeDefault = 'FahertyLargeDefault',
  FahertyLargeGrey = 'FahertyLargeGrey',
  FahertyLargeHighlight = 'FahertyLargeHighlight',
  FahertySmallDefault = 'FahertySmallDefault',
  FahertySmallGrey = 'FahertySmallGrey',
  FahertySmallHighlight = 'FahertySmallHighlight',
  FellowLargeDefault = 'FellowLargeDefault',
  FellowLargeGrey = 'FellowLargeGrey',
  FellowLargeHighlight = 'FellowLargeHighlight',
  FellowSmallDefault = 'FellowSmallDefault',
  FellowSmallGrey = 'FellowSmallGrey',
  FellowSmallHighlight = 'FellowSmallHighlight',
  FiveGuysLargeDefault = 'FiveGuysLargeDefault',
  FiveGuysLargeGrey = 'FiveGuysLargeGrey',
  FiveGuysLargeHighlight = 'FiveGuysLargeHighlight',
  FiveGuysSmallDefault = 'FiveGuysSmallDefault',
  FiveGuysSmallGrey = 'FiveGuysSmallGrey',
  FiveGuysSmallHighlight = 'FiveGuysSmallHighlight',
  FlowersUforDreamsIconDefault = 'FlowersUforDreamsIconDefault',
  FlowersUforDreamsLargeDefault = 'FlowersUforDreamsLargeDefault',
  FlowersUforDreamsLargeGrey = 'FlowersUforDreamsLargeGrey',
  FlowersUforDreamsLargeHighlight = 'FlowersUforDreamsLargeHighlight',
  FlowersUforDreamsSmallDefault = 'FlowersUforDreamsSmallDefault',
  FlowersUforDreamsSmallGrey = 'FlowersUforDreamsSmallGrey',
  FlowersUforDreamsSmallHighlight = 'FlowersUforDreamsSmallHighlight',
  ForterraLargeDefault = 'ForterraLargeDefault',
  ForterraLargeGrey = 'ForterraLargeGrey',
  ForterraLargeGreyU1 = 'ForterraLargeGreyU1',
  ForterraLargeHighlight = 'ForterraLargeHighlight',
  ForterraSmallDefault = 'ForterraSmallDefault',
  ForterraSmallGrey = 'ForterraSmallGrey',
  ForterraSmallHighlight = 'ForterraSmallHighlight',
  FrogslayerLargeDefault = 'FrogslayerLargeDefault',
  FrogslayerLargeGrey = 'FrogslayerLargeGrey',
  FrogslayerLargeGreyU1 = 'FrogslayerLargeGreyU1',
  FrogslayerLargeHighlight = 'FrogslayerLargeHighlight',
  FrogslayerSmallDefault = 'FrogslayerSmallDefault',
  FrogslayerSmallGrey = 'FrogslayerSmallGrey',
  FrogslayerSmallHighlight = 'FrogslayerSmallHighlight',
  FullstackLargeDefault = 'FullstackLargeDefault',
  FullstackLargeGrey = 'FullstackLargeGrey',
  FullstackLargeHighlight = 'FullstackLargeHighlight',
  FullstackSmallDefault = 'FullstackSmallDefault',
  FullstackSmallGrey = 'FullstackSmallGrey',
  FullstackSmallHighlight = 'FullstackSmallHighlight',
  G2Filled = 'G2Filled',
  G2Outline = 'G2Outline',
  GivebacksLargeDefault = 'GivebacksLargeDefault',
  GivebacksLargeGrey = 'GivebacksLargeGrey',
  GivebacksLargeHighlight = 'GivebacksLargeHighlight',
  GivebacksSmallDefault = 'GivebacksSmallDefault',
  GivebacksSmallGrey = 'GivebacksSmallGrey',
  GivebacksSmallGreyU1 = 'GivebacksSmallGreyU1',
  GivebacksSmallHighlight = 'GivebacksSmallHighlight',
  GorjanaLargeDefault = 'GorjanaLargeDefault',
  GorjanaLargeGrey = 'GorjanaLargeGrey',
  GorjanaLargeHighlight = 'GorjanaLargeHighlight',
  GorjanaSmallDefault = 'GorjanaSmallDefault',
  GorjanaSmallGrey = 'GorjanaSmallGrey',
  GorjanaSmallHighlight = 'GorjanaSmallHighlight',
  GorjanaSmallHighlightU1 = 'GorjanaSmallHighlightU1',
  HarverIconDefault = 'HarverIconDefault',
  HarverLargeDefault = 'HarverLargeDefault',
  HarverLargeGrey = 'HarverLargeGrey',
  HarverLargeHighlight = 'HarverLargeHighlight',
  HarverSmallDefault = 'HarverSmallDefault',
  HarverSmallGrey = 'HarverSmallGrey',
  HarverSmallHighlight = 'HarverSmallHighlight',
  HighNoonLargeDefault = 'HighNoonLargeDefault',
  HighNoonLargeGrey = 'HighNoonLargeGrey',
  HighNoonLargeHighlight = 'HighNoonLargeHighlight',
  HighNoonSmallDefault = 'HighNoonSmallDefault',
  HighNoonSmallGrey = 'HighNoonSmallGrey',
  HighNoonSmallHighlight = 'HighNoonSmallHighlight',
  HoneyloveLargeDefault = 'HoneyloveLargeDefault',
  HoneyloveLargeGrey = 'HoneyloveLargeGrey',
  HoneyloveLargeHighlight = 'HoneyloveLargeHighlight',
  HoneyloveSmallDefault = 'HoneyloveSmallDefault',
  HoneyloveSmallGrey = 'HoneyloveSmallGrey',
  HoneyloveSmallHighlight = 'HoneyloveSmallHighlight',
  HovercraftLargeDefault = 'HovercraftLargeDefault',
  HovercraftLargeGrey = 'HovercraftLargeGrey',
  HovercraftLargeHighlight = 'HovercraftLargeHighlight',
  HovercraftSmallDefault = 'HovercraftSmallDefault',
  HovercraftSmallGrey = 'HovercraftSmallGrey',
  HovercraftSmallHighlight = 'HovercraftSmallHighlight',
  HuddleUpLargeDefault = 'HuddleUpLargeDefault',
  HuddleUpLargeGrey = 'HuddleUpLargeGrey',
  HuddleUpLargeHighlight = 'HuddleUpLargeHighlight',
  HuddleUpSmallDefault = 'HuddleUpSmallDefault',
  HuddleUpSmallGrey = 'HuddleUpSmallGrey',
  HuddleUpSmallHighlight = 'HuddleUpSmallHighlight',
  JasperIconDefault = 'JasperIconDefault',
  JasperLargeDefault = 'JasperLargeDefault',
  JasperLargeGrey = 'JasperLargeGrey',
  JasperLargeHighlight = 'JasperLargeHighlight',
  JasperLargeHighlightU1 = 'JasperLargeHighlightU1',
  JasperSmallDefault = 'JasperSmallDefault',
  JasperSmallGrey = 'JasperSmallGrey',
  JasperSmallGreyU1 = 'JasperSmallGreyU1',
  JasperSmallHighlight = 'JasperSmallHighlight',
  LandSouthConstructionLargeDefault = 'LandSouthConstructionLargeDefault',
  LandSouthConstructionLargeGrey = 'LandSouthConstructionLargeGrey',
  LandSouthConstructionLargeHighlight = 'LandSouthConstructionLargeHighlight',
  LandSouthConstructionSmallDefault = 'LandSouthConstructionSmallDefault',
  LandSouthConstructionSmallGrey = 'LandSouthConstructionSmallGrey',
  LandSouthConstructionSmallHighlight = 'LandSouthConstructionSmallHighlight',
  LightforceIconDefault = 'LightforceIconDefault',
  LightforceLargeDefault = 'LightforceLargeDefault',
  LightforceLargeGrey = 'LightforceLargeGrey',
  LightforceLargeHighlight = 'LightforceLargeHighlight',
  LightforceSmallDefault = 'LightforceSmallDefault',
  LightforceSmallGrey = 'LightforceSmallGrey',
  LightforceSmallHighlight = 'LightforceSmallHighlight',
  LiquidDeathLargeDefault = 'LiquidDeathLargeDefault',
  LiquidDeathLargeGrey = 'LiquidDeathLargeGrey',
  LiquidDeathLargeHighlight = 'LiquidDeathLargeHighlight',
  LiquidDeathSmallDefault = 'LiquidDeathSmallDefault',
  LiquidDeathSmallGrey = 'LiquidDeathSmallGrey',
  LiquidDeathSmallHighlight = 'LiquidDeathSmallHighlight',
  LiteratiIconDefault = 'LiteratiIconDefault',
  LiteratiLargeDefault = 'LiteratiLargeDefault',
  LiteratiLargeGrey = 'LiteratiLargeGrey',
  LiteratiLargeHighlight = 'LiteratiLargeHighlight',
  LiteratiSmallDefault = 'LiteratiSmallDefault',
  LiteratiSmallGrey = 'LiteratiSmallGrey',
  LiteratiSmallHighlight = 'LiteratiSmallHighlight',
  LittleWordsProjectLargeDefault = 'LittleWordsProjectLargeDefault',
  LittleWordsProjectLargeGrey = 'LittleWordsProjectLargeGrey',
  LittleWordsProjectLargeHighlight = 'LittleWordsProjectLargeHighlight',
  LittleWordsProjectSmallDefault = 'LittleWordsProjectSmallDefault',
  LittleWordsProjectSmallGrey = 'LittleWordsProjectSmallGrey',
  LittleWordsProjectSmallHighlight = 'LittleWordsProjectSmallHighlight',
  M9SolutionsLargeDefault = 'M9SolutionsLargeDefault',
  M9SolutionsLargeGrey = 'M9SolutionsLargeGrey',
  M9SolutionsLargeHighlight = 'M9SolutionsLargeHighlight',
  M9SolutionsSmallDefault = 'M9SolutionsSmallDefault',
  M9SolutionsSmallGrey = 'M9SolutionsSmallGrey',
  M9SolutionsSmallHighlight = 'M9SolutionsSmallHighlight',
  MadtreeLargeDefault = 'MadtreeLargeDefault',
  MadtreeLargeGrey = 'MadtreeLargeGrey',
  MadtreeLargeHighlight = 'MadtreeLargeHighlight',
  MadtreeSmallDefault = 'MadtreeSmallDefault',
  MadtreeSmallGrey = 'MadtreeSmallGrey',
  MadtreeSmallHighlight = 'MadtreeSmallHighlight',
  MonteithLargeDefault = 'MonteithLargeDefault',
  MonteithLargeGrey = 'MonteithLargeGrey',
  MonteithLargeHighlight = 'MonteithLargeHighlight',
  MonteithSmallDefault = 'MonteithSmallDefault',
  MonteithSmallGrey = 'MonteithSmallGrey',
  MonteithSmallHighlight = 'MonteithSmallHighlight',
  MorningConsultIconDefault = 'MorningConsultIconDefault',
  MorningConsultLargeDefault = 'MorningConsultLargeDefault',
  MorningConsultLargeDefaultU1 = 'MorningConsultLargeDefaultU1',
  MorningConsultLargeGrey = 'MorningConsultLargeGrey',
  MorningConsultLargeHighlight = 'MorningConsultLargeHighlight',
  MorningConsultSmallDefault = 'MorningConsultSmallDefault',
  MorningConsultSmallDefaultU1 = 'MorningConsultSmallDefaultU1',
  MorningConsultSmallGrey = 'MorningConsultSmallGrey',
  MorningConsultSmallHighlight = 'MorningConsultSmallHighlight',
  NexhealthLargeDefault = 'NexhealthLargeDefault',
  NexhealthLargeGrey = 'NexhealthLargeGrey',
  NexhealthLargeHighlight = 'NexhealthLargeHighlight',
  NexhealthSmallDefault = 'NexhealthSmallDefault',
  NexhealthSmallGrey = 'NexhealthSmallGrey',
  NexhealthSmallGreyU1 = 'NexhealthSmallGreyU1',
  NexhealthSmallHighlight = 'NexhealthSmallHighlight',
  NIHAOCulturalArtsCenterLargeDefault = 'NIHAOCulturalArtsCenterLargeDefault',
  NIHAOCulturalArtsCenterLargeGrey = 'NIHAOCulturalArtsCenterLargeGrey',
  NIHAOCulturalArtsCenterLargeHighlight = 'NIHAOCulturalArtsCenterLargeHighlight',
  NIHAOCulturalArtsCenterSmallDefault = 'NIHAOCulturalArtsCenterSmallDefault',
  NIHAOCulturalArtsCenterSmallGrey = 'NIHAOCulturalArtsCenterSmallGrey',
  NIHAOCulturalArtsCenterSmallHighlight = 'NIHAOCulturalArtsCenterSmallHighlight',
  NuvoairLargeDefault = 'NuvoairLargeDefault',
  NuvoairLargeGrey = 'NuvoairLargeGrey',
  NuvoairLargeHighlight = 'NuvoairLargeHighlight',
  NuvoairSmallDefault = 'NuvoairSmallDefault',
  NuvoairSmallDefaultU1 = 'NuvoairSmallDefaultU1',
  NuvoairSmallGrey = 'NuvoairSmallGrey',
  NuvoairSmallHighlight = 'NuvoairSmallHighlight',
  OpenDoorLegalLargeDefault = 'OpenDoorLegalLargeDefault',
  OpenDoorLegalLargeGrey = 'OpenDoorLegalLargeGrey',
  OpenDoorLegalLargeHighlight = 'OpenDoorLegalLargeHighlight',
  OpenDoorLegalSmallDefault = 'OpenDoorLegalSmallDefault',
  OpenDoorLegalSmallGrey = 'OpenDoorLegalSmallGrey',
  OpenDoorLegalSmallHighlight = 'OpenDoorLegalSmallHighlight',
  OssoVRLargeDefault = 'OssoVRLargeDefault',
  OssoVRLargeGrey = 'OssoVRLargeGrey',
  OssoVRLargeHighlight = 'OssoVRLargeHighlight',
  OssoVRSmallDefault = 'OssoVRSmallDefault',
  OssoVRSmallGrey = 'OssoVRSmallGrey',
  OssoVRSmallHighlight = 'OssoVRSmallHighlight',
  OurPlaceLargeDefault = 'OurPlaceLargeDefault',
  OurPlaceLargeGrey = 'OurPlaceLargeGrey',
  OurPlaceLargeHighlight = 'OurPlaceLargeHighlight',
  OurPlaceSmallDefault = 'OurPlaceSmallDefault',
  OurPlaceSmallGrey = 'OurPlaceSmallGrey',
  OurPlaceSmallHighlight = 'OurPlaceSmallHighlight',
  PamperedPetsInnLargeDefault = 'PamperedPetsInnLargeDefault',
  PamperedPetsInnLargeGrey = 'PamperedPetsInnLargeGrey',
  PamperedPetsInnLargeHighlight = 'PamperedPetsInnLargeHighlight',
  PamperedPetsInnSmallDefault = 'PamperedPetsInnSmallDefault',
  PamperedPetsInnSmallGrey = 'PamperedPetsInnSmallGrey',
  PamperedPetsInnSmallHighlight = 'PamperedPetsInnSmallHighlight',
  PenntexConstructionLargeDefault = 'PenntexConstructionLargeDefault',
  PenntexConstructionLargeGrey = 'PenntexConstructionLargeGrey',
  PenntexConstructionLargeHighlight = 'PenntexConstructionLargeHighlight',
  PenntexConstructionSmallDefault = 'PenntexConstructionSmallDefault',
  PenntexConstructionSmallGrey = 'PenntexConstructionSmallGrey',
  PenntexConstructionSmallHighlight = 'PenntexConstructionSmallHighlight',
  PopstrokeLargeDefault = 'PopstrokeLargeDefault',
  PopstrokeLargeGrey = 'PopstrokeLargeGrey',
  PopstrokeLargeHighlight = 'PopstrokeLargeHighlight',
  PopstrokeSmallDefault = 'PopstrokeSmallDefault',
  PopstrokeSmallGrey = 'PopstrokeSmallGrey',
  PopstrokeSmallHighlight = 'PopstrokeSmallHighlight',
  PortlandClinicLargeDefault = 'PortlandClinicLargeDefault',
  PortlandClinicLargeGrey = 'PortlandClinicLargeGrey',
  PortlandClinicLargeHighlight = 'PortlandClinicLargeHighlight',
  PortlandClinicSmallDefault = 'PortlandClinicSmallDefault',
  PortlandClinicSmallGrey = 'PortlandClinicSmallGrey',
  PortlandClinicSmallHighlight = 'PortlandClinicSmallHighlight',
  QualioLargeDefault = 'QualioLargeDefault',
  QualioLargeGrey = 'QualioLargeGrey',
  QualioLargeHighlight = 'QualioLargeHighlight',
  QualioSmallDefault = 'QualioSmallDefault',
  QualioSmallGrey = 'QualioSmallGrey',
  QualioSmallHighlight = 'QualioSmallHighlight',
  QuinceLargeDefault = 'QuinceLargeDefault',
  QuinceLargeDefaultU1 = 'QuinceLargeDefaultU1',
  QuinceLargeGrey = 'QuinceLargeGrey',
  QuinceLargeHighlight = 'QuinceLargeHighlight',
  QuinceSmallDefault = 'QuinceSmallDefault',
  QuinceSmallGrey = 'QuinceSmallGrey',
  QuinceSmallHighlight = 'QuinceSmallHighlight',
  RadAILargeDefault = 'RadAILargeDefault',
  RadAILargeGrey = 'RadAILargeGrey',
  RadAILargeHighlight = 'RadAILargeHighlight',
  RadAISmallDefault = 'RadAISmallDefault',
  RadAISmallGrey = 'RadAISmallGrey',
  RadAISmallHighlight = 'RadAISmallHighlight',
  RealfinityLargeDefault = 'RealfinityLargeDefault',
  RealfinityLargeGrey = 'RealfinityLargeGrey',
  RealfinityLargeHighlight = 'RealfinityLargeHighlight',
  RealfinitySmallDefault = 'RealfinitySmallDefault',
  RealfinitySmallGrey = 'RealfinitySmallGrey',
  RealfinitySmallHighlight = 'RealfinitySmallHighlight',
  RememberMeSeniorCareLargeDefault = 'RememberMeSeniorCareLargeDefault',
  RememberMeSeniorCareLargeGrey = 'RememberMeSeniorCareLargeGrey',
  RememberMeSeniorCareLargeHighlight = 'RememberMeSeniorCareLargeHighlight',
  RememberMeSeniorCareSmallDefault = 'RememberMeSeniorCareSmallDefault',
  RememberMeSeniorCareSmallGrey = 'RememberMeSeniorCareSmallGrey',
  RememberMeSeniorCareSmallHighlight = 'RememberMeSeniorCareSmallHighlight',
  ReservLargeDefault = 'ReservLargeDefault',
  ReservLargeGrey = 'ReservLargeGrey',
  ReservLargeGreyU1 = 'ReservLargeGreyU1',
  ReservLargeHighlight = 'ReservLargeHighlight',
  ReservSmallDefault = 'ReservSmallDefault',
  ReservSmallGrey = 'ReservSmallGrey',
  ReservSmallHighlight = 'ReservSmallHighlight',
  RhythmLargeDefault = 'RhythmLargeDefault',
  RhythmLargeGrey = 'RhythmLargeGrey',
  RhythmLargeHighlight = 'RhythmLargeHighlight',
  RhythmSmallDefault = 'RhythmSmallDefault',
  RhythmSmallGrey = 'RhythmSmallGrey',
  RhythmSmallHighlight = 'RhythmSmallHighlight',
  SandboxVRLargeDefault = 'SandboxVRLargeDefault',
  SandboxVRLargeGrey = 'SandboxVRLargeGrey',
  SandboxVRLargeHighlight = 'SandboxVRLargeHighlight',
  SandboxVRSmallDefault = 'SandboxVRSmallDefault',
  SandboxVRSmallGrey = 'SandboxVRSmallGrey',
  SandboxVRSmallHighlight = 'SandboxVRSmallHighlight',
  SayariIconDefault = 'SayariIconDefault',
  SayariIconDefaultU1 = 'SayariIconDefaultU1',
  SayariLargeDefault = 'SayariLargeDefault',
  SayariLargeGrey = 'SayariLargeGrey',
  SayariLargeHighlight = 'SayariLargeHighlight',
  SayariSmallDefault = 'SayariSmallDefault',
  SayariSmallGrey = 'SayariSmallGrey',
  SayariSmallHighlight = 'SayariSmallHighlight',
  ScoutLargeDefault = 'ScoutLargeDefault',
  ScoutLargeGrey = 'ScoutLargeGrey',
  ScoutLargeHighlight = 'ScoutLargeHighlight',
  ScoutSmallDefault = 'ScoutSmallDefault',
  ScoutSmallGrey = 'ScoutSmallGrey',
  ScoutSmallHighlight = 'ScoutSmallHighlight',
  SealedLargeDefault = 'SealedLargeDefault',
  SealedLargeGrey = 'SealedLargeGrey',
  SealedLargeHighlight = 'SealedLargeHighlight',
  SealedSmallDefault = 'SealedSmallDefault',
  SealedSmallGrey = 'SealedSmallGrey',
  SealedSmallGreyU1 = 'SealedSmallGreyU1',
  SealedSmallHighlight = 'SealedSmallHighlight',
  SierraAIIconDefault = 'SierraAIIconDefault',
  SierraAILargeDefault = 'SierraAILargeDefault',
  SierraAILargeGrey = 'SierraAILargeGrey',
  SierraAILargeHighlight = 'SierraAILargeHighlight',
  SierraAISmallDefault = 'SierraAISmallDefault',
  SierraAISmallGrey = 'SierraAISmallGrey',
  SierraAISmallHighlight = 'SierraAISmallHighlight',
  SitemateLargeDefault = 'SitemateLargeDefault',
  SitemateLargeDefaultU1 = 'SitemateLargeDefaultU1',
  SitemateLargeGrey = 'SitemateLargeGrey',
  SitemateLargeHighlight = 'SitemateLargeHighlight',
  SitemateSmallDefault = 'SitemateSmallDefault',
  SitemateSmallGrey = 'SitemateSmallGrey',
  SitemateSmallHighlight = 'SitemateSmallHighlight',
  SitemateSmallHighlightU1 = 'SitemateSmallHighlightU1',
  SolidcoreLargeDefault = 'SolidcoreLargeDefault',
  SolidcoreLargeGrey = 'SolidcoreLargeGrey',
  SolidcoreLargeHighlight = 'SolidcoreLargeHighlight',
  SolidcoreSmallDefault = 'SolidcoreSmallDefault',
  SolidcoreSmallDefaultU1 = 'SolidcoreSmallDefaultU1',
  SolidcoreSmallGrey = 'SolidcoreSmallGrey',
  SolidcoreSmallHighlight = 'SolidcoreSmallHighlight',
  StackletLargeDefault = 'StackletLargeDefault',
  StackletLargeGrey = 'StackletLargeGrey',
  StackletLargeHighlight = 'StackletLargeHighlight',
  StackletSmallDefault = 'StackletSmallDefault',
  StackletSmallDefaultU1 = 'StackletSmallDefaultU1',
  StackletSmallGrey = 'StackletSmallGrey',
  StackletSmallHighlight = 'StackletSmallHighlight',
  StriveHealthLargeDefault = 'StriveHealthLargeDefault',
  StriveHealthLargeGrey = 'StriveHealthLargeGrey',
  StriveHealthLargeHighlight = 'StriveHealthLargeHighlight',
  StriveHealthSmallDefault = 'StriveHealthSmallDefault',
  StriveHealthSmallGrey = 'StriveHealthSmallGrey',
  StriveHealthSmallHighlight = 'StriveHealthSmallHighlight',
  StructureCraftLargeDefault = 'StructureCraftLargeDefault',
  StructureCraftLargeGrey = 'StructureCraftLargeGrey',
  StructureCraftLargeHighlight = 'StructureCraftLargeHighlight',
  StructureCraftSmallDefault = 'StructureCraftSmallDefault',
  StructureCraftSmallGrey = 'StructureCraftSmallGrey',
  StructureCraftSmallHighlight = 'StructureCraftSmallHighlight',
  SugaredBronzedLargeDefault = 'SugaredBronzedLargeDefault',
  SugaredBronzedLargeGrey = 'SugaredBronzedLargeGrey',
  SugaredBronzedLargeHighlight = 'SugaredBronzedLargeHighlight',
  SugaredBronzedSmallDefault = 'SugaredBronzedSmallDefault',
  SugaredBronzedSmallGrey = 'SugaredBronzedSmallGrey',
  SugaredBronzedSmallHighlight = 'SugaredBronzedSmallHighlight',
  SupercutsLargeDefault = 'SupercutsLargeDefault',
  SupercutsLargeGrey = 'SupercutsLargeGrey',
  SupercutsLargeHighlight = 'SupercutsLargeHighlight',
  SupercutsSmallDefault = 'SupercutsSmallDefault',
  SupercutsSmallGrey = 'SupercutsSmallGrey',
  SupercutsSmallHighlight = 'SupercutsSmallHighlight',
  SuperhumanIconDefault = 'SuperhumanIconDefault',
  SuperhumanLargeDefault = 'SuperhumanLargeDefault',
  SuperhumanLargeGrey = 'SuperhumanLargeGrey',
  SuperhumanLargeHighlight = 'SuperhumanLargeHighlight',
  SuperhumanSmallDefault = 'SuperhumanSmallDefault',
  SuperhumanSmallGrey = 'SuperhumanSmallGrey',
  SuperhumanSmallHighlight = 'SuperhumanSmallHighlight',
  SwordLargeDefault = 'SwordLargeDefault',
  SwordLargeGrey = 'SwordLargeGrey',
  SwordLargeHighlight = 'SwordLargeHighlight',
  SwordSmallDefault = 'SwordSmallDefault',
  SwordSmallGrey = 'SwordSmallGrey',
  SwordSmallGreyU1 = 'SwordSmallGreyU1',
  SwordSmallHighlight = 'SwordSmallHighlight',
  TacosDiabloLargeDefault = 'TacosDiabloLargeDefault',
  TacosDiabloLargeGrey = 'TacosDiabloLargeGrey',
  TacosDiabloLargeGreyU1 = 'TacosDiabloLargeGreyU1',
  TacosDiabloLargeHighlight = 'TacosDiabloLargeHighlight',
  TacosDiabloSmallDefault = 'TacosDiabloSmallDefault',
  TacosDiabloSmallGrey = 'TacosDiabloSmallGrey',
  TacosDiabloSmallHighlight = 'TacosDiabloSmallHighlight',
  TaskrabbitLargeDefault = 'TaskrabbitLargeDefault',
  TaskrabbitLargeGrey = 'TaskrabbitLargeGrey',
  TaskrabbitLargeHighlight = 'TaskrabbitLargeHighlight',
  TaskrabbitSmallDefault = 'TaskrabbitSmallDefault',
  TaskrabbitSmallGrey = 'TaskrabbitSmallGrey',
  TaskrabbitSmallHighlight = 'TaskrabbitSmallHighlight',
  TherabodyLargeDefault = 'TherabodyLargeDefault',
  TherabodyLargeGrey = 'TherabodyLargeGrey',
  TherabodyLargeHighlight = 'TherabodyLargeHighlight',
  TherabodySmallDefault = 'TherabodySmallDefault',
  TherabodySmallGrey = 'TherabodySmallGrey',
  TherabodySmallHighlight = 'TherabodySmallHighlight',
  ThriveGlobalLargeDefault = 'ThriveGlobalLargeDefault',
  ThriveGlobalLargeGrey = 'ThriveGlobalLargeGrey',
  ThriveGlobalLargeHighlight = 'ThriveGlobalLargeHighlight',
  ThriveGlobalSmallDefault = 'ThriveGlobalSmallDefault',
  ThriveGlobalSmallGrey = 'ThriveGlobalSmallGrey',
  ThriveGlobalSmallHighlight = 'ThriveGlobalSmallHighlight',
  ThumaLargeDefault = 'ThumaLargeDefault',
  ThumaLargeGrey = 'ThumaLargeGrey',
  ThumaLargeHighlight = 'ThumaLargeHighlight',
  ThumaSmallDefault = 'ThumaSmallDefault',
  ThumaSmallGrey = 'ThumaSmallGrey',
  ThumaSmallHighlight = 'ThumaSmallHighlight',
  TrashButlerLargeDefault = 'TrashButlerLargeDefault',
  TrashButlerLargeGrey = 'TrashButlerLargeGrey',
  TrashButlerLargeHighlight = 'TrashButlerLargeHighlight',
  TrashButlerLargeHighlightU1 = 'TrashButlerLargeHighlightU1',
  TrashButlerSmallDefault = 'TrashButlerSmallDefault',
  TrashButlerSmallGrey = 'TrashButlerSmallGrey',
  TrashButlerSmallHighlight = 'TrashButlerSmallHighlight',
  TurntideLargeDefault = 'TurntideLargeDefault',
  TurntideLargeGrey = 'TurntideLargeGrey',
  TurntideLargeHighlight = 'TurntideLargeHighlight',
  TurntideSmallDefault = 'TurntideSmallDefault',
  TurntideSmallGrey = 'TurntideSmallGrey',
  TurntideSmallHighlight = 'TurntideSmallHighlight',
  Uo8tLargeDefault = 'Uo8tLargeDefault',
  Uo8tLargeGrey = 'Uo8tLargeGrey',
  Uo8tLargeHighlight = 'Uo8tLargeHighlight',
  Uo8tSmallDefault = 'Uo8tSmallDefault',
  Uo8tSmallGrey = 'Uo8tSmallGrey',
  Uo8tSmallHighlight = 'Uo8tSmallHighlight',
  VamboraLargeDefault = 'VamboraLargeDefault',
  VamboraLargeGrey = 'VamboraLargeGrey',
  VamboraLargeHighlight = 'VamboraLargeHighlight',
  VamboraLargeHighlightU1 = 'VamboraLargeHighlightU1',
  VamboraSmallDefault = 'VamboraSmallDefault',
  VamboraSmallGrey = 'VamboraSmallGrey',
  VamboraSmallHighlight = 'VamboraSmallHighlight',
  VCheckIconDefault = 'VCheckIconDefault',
  VCheckLargeDefault = 'VCheckLargeDefault',
  VCheckLargeGrey = 'VCheckLargeGrey',
  VCheckLargeHighlight = 'VCheckLargeHighlight',
  VCheckSmallDefault = 'VCheckSmallDefault',
  VCheckSmallGrey = 'VCheckSmallGrey',
  VCheckSmallHighlight = 'VCheckSmallHighlight',
  WalkerAdvertisingLargeDefault = 'WalkerAdvertisingLargeDefault',
  WalkerAdvertisingLargeGrey = 'WalkerAdvertisingLargeGrey',
  WalkerAdvertisingLargeHighlight = 'WalkerAdvertisingLargeHighlight',
  WalkerAdvertisingSmallDefault = 'WalkerAdvertisingSmallDefault',
  WalkerAdvertisingSmallGrey = 'WalkerAdvertisingSmallGrey',
  WalkerAdvertisingSmallHighlight = 'WalkerAdvertisingSmallHighlight'
}
