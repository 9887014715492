import { Suspense, useMemo } from 'react';

import { twMerge } from 'tailwind-merge';

import { FlagName } from './flagName';
import FLAGS from './FlagsList';

interface FlagProps extends React.SVGAttributes<SVGElement> { flagName: FlagName; }

const FlagFallback = () => {
  return <div className="w-6 h-6 bg-white/5 animate-pulse rounded" />;
};

const RenderFlag = ({
  className, flagName, ...props
}: FlagProps) => {
  const FlagComponent = useMemo(() => FLAGS[flagName as FlagName] ?? null, [name]);

  if (!FlagComponent) {
    return null;
  }

  return (
    <FlagComponent
      className={twMerge('w-6 h-6', className)}
      {...props}
    />
  );
};

const Flag = ({
  className, flagName, ...props
}: FlagProps) => {
  return (
    <Suspense fallback={<FlagFallback />}>
      <RenderFlag flagName={flagName} {...props} />
    </Suspense>
  );
};

export default Flag;
