export enum FlagName {
  ADAndorra = 'ADAndorra',
  AEUnitedArabEmirates = 'AEUnitedArabEmirates',
  AFAfghanistan = 'AFAfghanistan',
  AGAntiguaAndBarbuda = 'AGAntiguaAndBarbuda',
  ALAlbania = 'ALAlbania',
  AMArmenia = 'AMArmenia',
  AOAnguilla = 'AOAnguilla',
  AOAnguilla1 = 'AOAnguilla1',
  AQAntarctica = 'AQAntarctica',
  ARArgentina = 'ARArgentina',
  ASAmericanSamoa = 'ASAmericanSamoa',
  ATAustria = 'ATAustria',
  AUAustralia = 'AUAustralia',
  AWAruba = 'AWAruba',
  AXAlandIslands = 'AXAlandIslands',
  AXAlandIslands1 = 'AXAlandIslands1',
  AZAzerbaijan = 'AZAzerbaijan',
  AZAzerbaijan1 = 'AZAzerbaijan1',
  AZAzerbaijan11 = 'AZAzerbaijan11',
  AZAzerbaijan12 = 'AZAzerbaijan12',
  BABosniaAndHerzegovina = 'BABosniaAndHerzegovina',
  BBBarbados = 'BBBarbados',
  BDBangladesh = 'BDBangladesh',
  BEBelgium = 'BEBelgium',
  BFBurkinaFaso = 'BFBurkinaFaso',
  BGBulgaria = 'BGBulgaria',
  BGBulgaria1 = 'BGBulgaria1',
  BHBahrain = 'BHBahrain',
  BIBurundi = 'BIBurundi',
  BJBenin = 'BJBenin',
  BLSaintBarthlemy = 'BLSaintBarthlemy',
  BMBermuda = 'BMBermuda',
  BNBruneiDarussalam = 'BNBruneiDarussalam',
  BOBolivia = 'BOBolivia',
  BQBonaire = 'BQBonaire',
  BRBrazil = 'BRBrazil',
  BSBahamas = 'BSBahamas',
  BTBhutan = 'BTBhutan',
  BVBouvetIsland = 'BVBouvetIsland',
  BWBotswana = 'BWBotswana',
  BYBelarus = 'BYBelarus',
  BZBelize = 'BZBelize',
  CACanada = 'CACanada',
  CCCocosIsland = 'CCCocosIsland',
  CDDemocraticRepublicOfTheCongo = 'CDDemocraticRepublicOfTheCongo',
  CFCentralAfricanRepublic = 'CFCentralAfricanRepublic',
  CGRepublicOfTheCongo = 'CGRepublicOfTheCongo',
  CHSwitzerland = 'CHSwitzerland',
  CICoteDIvoire = 'CICoteDIvoire',
  CKCookIslands = 'CKCookIslands',
  CLChile = 'CLChile',
  CMCameroon = 'CMCameroon',
  CNChina = 'CNChina',
  COColombia = 'COColombia',
  CRCostaRica = 'CRCostaRica',
  CUCuba = 'CUCuba',
  CVCaboVerde = 'CVCaboVerde',
  CWCuracao = 'CWCuracao',
  CXChristmasIsland = 'CXChristmasIsland',
  CYCyprus = 'CYCyprus',
  CZCzechRepublic = 'CZCzechRepublic',
  DEGermany = 'DEGermany',
  DJDjibouti = 'DJDjibouti',
  DKDenmark = 'DKDenmark',
  DMDominica = 'DMDominica',
  DODominicanRepublic = 'DODominicanRepublic',
  DZAlgeria = 'DZAlgeria',
  DZAlgeria1 = 'DZAlgeria1',
  ECEcuador = 'ECEcuador',
  EEEstonia = 'EEEstonia',
  EGEgypt = 'EGEgypt',
  EGEgypt1 = 'EGEgypt1',
  EGEgypt2 = 'EGEgypt2',
  EREritrea = 'EREritrea',
  EREritrea1 = 'EREritrea1',
  ESSpain = 'ESSpain',
  ETEthiopia = 'ETEthiopia',
  FIFinland = 'FIFinland',
  FJFiji = 'FJFiji',
  FJFiji1 = 'FJFiji1',
  FJFiji2 = 'FJFiji2',
  FKIslasMalvinasFalklandIslands = 'FKIslasMalvinasFalklandIslands',
  FOFaroeIslands = 'FOFaroeIslands',
  FRFrance = 'FRFrance',
  GAGabon = 'GAGabon',
  GBUnitedKingdom = 'GBUnitedKingdom',
  GDGrenada = 'GDGrenada',
  GDGrenada1 = 'GDGrenada1',
  GDGrenada2 = 'GDGrenada2',
  GEGeorgia = 'GEGeorgia',
  GEGeorgia1 = 'GEGeorgia1',
  GEGeorgia2 = 'GEGeorgia2',
  GFFrenchGuiana = 'GFFrenchGuiana',
  GGGuernsey = 'GGGuernsey',
  GHGhana = 'GHGhana',
  GIGibraltar = 'GIGibraltar',
  GLGreenland = 'GLGreenland',
  GMGambia = 'GMGambia',
  GNGuinea = 'GNGuinea',
  GPGuadeloupe = 'GPGuadeloupe',
  GQEquatorialGuinea = 'GQEquatorialGuinea',
  GRGreece = 'GRGreece',
  GTGuatemala = 'GTGuatemala',
  GUGuam = 'GUGuam',
  GWGuineaBissau = 'GWGuineaBissau',
  GYGuyana = 'GYGuyana',
  HKHongKong = 'HKHongKong',
  HKHongKong1 = 'HKHongKong1',
  HNHonduras = 'HNHonduras',
  HRCroatia = 'HRCroatia',
  HRCroatia1 = 'HRCroatia1',
  HRCroatia2 = 'HRCroatia2',
  HTHaiti = 'HTHaiti',
  HUHungary = 'HUHungary',
  IDIndonesia = 'IDIndonesia',
  IEIreland = 'IEIreland',
  IEIreland1 = 'IEIreland1',
  ILIsrael = 'ILIsrael',
  IMIsleOfMan = 'IMIsleOfMan',
  INIndia = 'INIndia',
  IOBritishIndianOceanTerritory = 'IOBritishIndianOceanTerritory',
  IQIraq = 'IQIraq',
  IRIran = 'IRIran',
  ISIceland = 'ISIceland',
  ITItaly = 'ITItaly',
  ITLithuania = 'ITLithuania',
  JEJersey = 'JEJersey',
  JMJamaica = 'JMJamaica',
  JOJordan = 'JOJordan',
  JPJapan = 'JPJapan',
  KEKenya = 'KEKenya',
  KEKenya1 = 'KEKenya1',
  KGKyrgyzstan = 'KGKyrgyzstan',
  KHCambodia = 'KHCambodia',
  KIKiribati = 'KIKiribati',
  KMComoros = 'KMComoros',
  KNSaintKittsAndNevis = 'KNSaintKittsAndNevis',
  KNSaintKittsAndNevis1 = 'KNSaintKittsAndNevis1',
  KNSaintKittsAndNevis2 = 'KNSaintKittsAndNevis2',
  KPNorthKorea = 'KPNorthKorea',
  KRSouthKorea = 'KRSouthKorea',
  KWKuwait = 'KWKuwait',
  KYCaymanIslands = 'KYCaymanIslands',
  KZKazakhstan = 'KZKazakhstan',
  LALaos = 'LALaos',
  LBLebanon = 'LBLebanon',
  LCSaintLucia = 'LCSaintLucia',
  LILiechtenstein = 'LILiechtenstein',
  LKSriLanka = 'LKSriLanka',
  LRLiberia = 'LRLiberia',
  LSLesotho = 'LSLesotho',
  LULuxembourg = 'LULuxembourg',
  LVLatvia = 'LVLatvia',
  LVLatvia1 = 'LVLatvia1',
  LYLibya = 'LYLibya',
  MAMorocco = 'MAMorocco',
  MCMonaco = 'MCMonaco',
  MCMonaco1 = 'MCMonaco1',
  MCMonaco2 = 'MCMonaco2',
  MDMoldova = 'MDMoldova',
  MEMontenegro = 'MEMontenegro',
  MGMadagascar = 'MGMadagascar',
  MGMadagascar1 = 'MGMadagascar1',
  MGMadagascar2 = 'MGMadagascar2',
  MHMarshallIslands = 'MHMarshallIslands',
  MKNorthMacedonia = 'MKNorthMacedonia',
  MLMali = 'MLMali',
  MMMyanmar = 'MMMyanmar',
  MNMongolia = 'MNMongolia',
  MNMongolia1 = 'MNMongolia1',
  MOMacau = 'MOMacau',
  MQMartinique = 'MQMartinique',
  MRMauritania = 'MRMauritania',
  MSMontserrat = 'MSMontserrat',
  MTMalta = 'MTMalta',
  MUMauritius = 'MUMauritius',
  MVMaldives = 'MVMaldives',
  MVMaldives1 = 'MVMaldives1',
  MWMalawi = 'MWMalawi',
  MXMexico = 'MXMexico',
  MYMalaysia = 'MYMalaysia',
  MZMozambique = 'MZMozambique',
  NANamibia = 'NANamibia',
  NCNewCeledonia = 'NCNewCeledonia',
  NENiger = 'NENiger',
  NFNorfolkIsland = 'NFNorfolkIsland',
  NGNigeria = 'NGNigeria',
  NINicaragua = 'NINicaragua',
  NLNetherlands = 'NLNetherlands',
  NONorway = 'NONorway',
  NPNepal = 'NPNepal',
  NRNauru = 'NRNauru',
  NUNiue = 'NUNiue',
  NZNewZealand = 'NZNewZealand',
  OMOman = 'OMOman',
  PAPanama = 'PAPanama',
  PEPeru = 'PEPeru',
  PFFrenchPolynesia = 'PFFrenchPolynesia',
  PGPapuaNewGuinea = 'PGPapuaNewGuinea',
  PGPapuaNewGuinea1 = 'PGPapuaNewGuinea1',
  PHPhilippines = 'PHPhilippines',
  PKPakistan = 'PKPakistan',
  PLPoland = 'PLPoland',
  PNPitcairn = 'PNPitcairn',
  PRPuertoRico = 'PRPuertoRico',
  PSStateOfPalestine = 'PSStateOfPalestine',
  PSStateOfPalestine1 = 'PSStateOfPalestine1',
  PTPortugal = 'PTPortugal',
  PWPalau = 'PWPalau',
  PYParaguay = 'PYParaguay',
  QAQatar = 'QAQatar',
  REReunion = 'REReunion',
  RoRomania = 'RoRomania',
  RSSerbia = 'RSSerbia',
  RSSerbia1 = 'RSSerbia1',
  RSSerbia2 = 'RSSerbia2',
  RURussia = 'RURussia',
  RURussia1 = 'RURussia1',
  RWRwanda = 'RWRwanda',
  SASaudiArabia = 'SASaudiArabia',
  SBSolomonIslands = 'SBSolomonIslands',
  SCSeychelles = 'SCSeychelles',
  SDSudan = 'SDSudan',
  SESweden = 'SESweden',
  SGSingapore = 'SGSingapore',
  SGSingapore1 = 'SGSingapore1',
  SHSaintHelena = 'SHSaintHelena',
  SISlovenia = 'SISlovenia',
  SJSvalbardAndJanMayen = 'SJSvalbardAndJanMayen',
  SKSlovakia = 'SKSlovakia',
  SLSierraLeone = 'SLSierraLeone',
  SMSanMarino = 'SMSanMarino',
  SNSenegal = 'SNSenegal',
  SOSomalia = 'SOSomalia',
  SRSuriname = 'SRSuriname',
  SSSouthSudan = 'SSSouthSudan',
  STSaoTomeAndPrincipe = 'STSaoTomeAndPrincipe',
  SVElSalavador = 'SVElSalavador',
  SXSintMaarten = 'SXSintMaarten',
  SYSyria = 'SYSyria',
  SZEswatini = 'SZEswatini',
  SZEswatini1 = 'SZEswatini1',
  TCTurksAndCaicosIslands = 'TCTurksAndCaicosIslands',
  TCTurksAndCaicosIslands1 = 'TCTurksAndCaicosIslands1',
  TDChad = 'TDChad',
  TGTogo = 'TGTogo',
  THThailand = 'THThailand',
  TJTajikistan = 'TJTajikistan',
  TJTajikistan1 = 'TJTajikistan1',
  TKTokelau = 'TKTokelau',
  TLTimorLeste = 'TLTimorLeste',
  TLTimorLeste1 = 'TLTimorLeste1',
  TMTurkmenistan = 'TMTurkmenistan',
  TNTunisia = 'TNTunisia',
  TOTonga = 'TOTonga',
  TTTrinidadAndTobago = 'TTTrinidadAndTobago',
  TVTuvalu = 'TVTuvalu',
  TWTaiwan = 'TWTaiwan',
  TWTaiwan1 = 'TWTaiwan1',
  TZTanzania = 'TZTanzania',
  UAUkraine = 'UAUkraine',
  UGUganda = 'UGUganda',
  UMUnitedStatesMinorOutlyingIslands = 'UMUnitedStatesMinorOutlyingIslands',
  USUnitedStatesOfAmerica = 'USUnitedStatesOfAmerica',
  VAHolySee = 'VAHolySee',
  VCSaintVincentAndTheGrenadines = 'VCSaintVincentAndTheGrenadines',
  WSSamoa = 'WSSamoa',
  ZASouthAfrica = 'ZASouthAfrica'
}
