import { ReactNode } from 'react';

import clsx from 'clsx';

import { IconName } from '../../icons';
import { SurfaceLevel } from '../../types';
import Icon from '../Icon/Icon';

export interface AccordionProps {
  children: ReactNode;
  surfaceLevel: SurfaceLevel;
  title: string;
  className?: string;
  name?: string;
  paragraphClassName?: string;
  titleClassName?: string;
}

const Accordion = ({
  children,
  className,
  name,
  paragraphClassName,
  surfaceLevel = SurfaceLevel.L1,
  title,
  titleClassName,
}: AccordionProps) => {
  return (
    <details
      className={clsx(
        'text-text-secondary text-p1 group',
        surfaceLevel === SurfaceLevel.L0
          ? 'border-b border-border-l0 py-md first:pt-0'
          : 'bg-surface-l1 rounded-lg p-lg mb-lg',
        className
      )}
      name={name}
    >
      <summary className="list-none cursor-pointer">
        <div className="grid grid-cols-[1fr_48px] gap-xl w-full">
          <span
            className={clsx(
              'block peer font-medium',
              surfaceLevel === SurfaceLevel.L0
                ? 'text-p1 text-text-secondary can-hover:group-hover:text-text-primary group-open:text-text-primary transition-colors'
                : 'lg:text-h3 md:text-h4  text-text-primary',
              titleClassName
            )}
          >
            {title}
          </span>
          <div
            className={clsx(
              'transition-colors w-6 h-6 p-3 box-content rounded-sm fill-icon-primary',
              surfaceLevel === SurfaceLevel.L0 && 'bg-surface-l1 can-hover:hover:bg-surface-l1-active can-hover:group-hover:bg-surface-l1-active group-open:bg-surface-l1-active',
              surfaceLevel === SurfaceLevel.L1 && 'bg-surface-l2 can-hover:hover:bg-surface-l2-active can-hover:group-hover:bg-surface-l2-active group-open:bg-surface-l2-active'

            )}
          >
            <Icon className="group-open:rotate-180 transition-transform" name={IconName.ChevronDownIcon} />
          </div>
        </div>
      </summary>
      <div className={clsx('text-p1', paragraphClassName)}>{children}</div>
    </details>
  );
};

export default Accordion;
