// @TODO - convert this to TS so we can strongly type these variables
const spacing = {
  '2xl': '2xl',
  '2xs': '2xs',
  '3xl': '3xl',
  '3xs': '3xs',
  '4xl': '4xl',
  '4xs': '4xs',
  lg: 'lg',
  'margin-section-hero': 'margin-section-hero',
  'margin-section-lg': 'margin-section-lg',
  'margin-section-md': 'margin-section-md',
  md: 'md',
  none: '0',
  sm: 'sm',
  xl: 'xl',
  xs: 'xs',
};

// the order of the screens is the order in which the media queries are applied in css file
const screens = {
  'can-hover': { raw: '(hover: hover)' },
  sm: '420px',
  // eslint-disable-next-line perfectionist/sort-objects
  md: '768px',
  // eslint-disable-next-line perfectionist/sort-objects
  lg: '1024px',
  xl: '1440px',
};

const defaultSectionPaddingClassName = 'py-margin-section-md px-4';

const defaultSectionMarginClassName = 'mx-auto';

module.exports = {
  defaultSectionMarginClassName,
  defaultSectionPaddingClassName,
  screens,
  spacing,
};
