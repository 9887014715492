import { useState } from 'react';

import { twJoin } from 'tailwind-merge';

import { IconName } from '../../icons';
import Icon from '../Icon/Icon';

import type { BaseComponent } from '../../types';

export interface CheckboxProps extends BaseComponent {
  id: string;
  label: string;
  className?: string;
  defaultChecked?: boolean;
  groupName?: string;
}

const Checkbox = ({
  className,
  defaultChecked = false,
  groupName,
  id,
  label,
}: CheckboxProps) => {
  const [checked, setChecked] = useState<boolean>(defaultChecked);

  return (
    <div className={twJoin('relative flex items-center', className)}>
      <input
        checked={checked}
        className="peer opacity-0 absolute pointer-events-none"
        id={id}
        name={groupName}
        onChange={(event) => setChecked(event.target.checked)}
        type="checkbox"
      />
      <div className="absolute pointer-events-none left-4">
        <div className="relative w-5 h-5">
          <div className="absolute rounded-full border-md border-border-l1 w-full h-full" />
          <Icon
            className={twJoin(
              'transition-opacity absolute fill-border-active',
              checked ? 'opacity-100' : 'opacity-0'
            )}
            name={IconName.CheckCircleFilledIcon}
          />
        </div>
      </div>
      <label
        className="transition-colors w-full text-text-primary p-sm pl-12 gap-xs rounded-sm border-md border-border-l0 bg-surface-l1 peer-checked:font-medium peer-checked:border-border-active can-hover:hover:border-border-active"
        htmlFor={id}
      >
        {label}
      </label>
    </div>
  );
};

export default Checkbox;
