import {
  ComponentType,
  createContext,
  ReactNode,
  useMemo
} from 'react';

import { FormType, OpenpriseFieldKey } from '@rippling/utils';

interface SavaContextType {
  googleClientId: null | string;
  isProd: boolean;
  cfSpaceId?: string;
  debugMode?: boolean;
  env?: 'development' | 'local' | 'preview' | 'production',
  formatDate?: (date?: Date | number) => string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  linkComponent?: ComponentType<any>
  locale?: string;
  redirect?: (url: string) => void;
  rtlid?: string | string[];
  strings?: Record<string, string>
  submitForm?: (args: {
    formData: Partial<Record<OpenpriseFieldKey, string>>;
    formType: FormType;
    thankYouUrl: string;
  }) => Promise<unknown>
}

interface Props extends Omit<SavaContextType, 'isProd'> { children: ReactNode; }

export const SavaContext = createContext<null | SavaContextType>(null);

const SavaProvider = ({
  children, env, locale, ...props
}: Props) => {
  const formatDate = useMemo(() => {
    return new Intl.DateTimeFormat(locale, {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    }).format;
  }, [locale]);

  return (
    <SavaContext.Provider
      value={{
        ...props,
        env,
        formatDate,
        isProd: env === 'production',
        locale,
      }}
    >
      {children}
    </SavaContext.Provider>
  );
};

export default SavaProvider;
