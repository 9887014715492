import { useState } from 'react';

import { twJoin } from 'tailwind-merge';

import type { BaseComponent } from '../../types';

export interface RadioOptionProps extends BaseComponent {
  id: string;
  label: string;
  className?: string;
  groupName?: string;
}

const RadioOption = ({
  className, groupName, id, label,
}: RadioOptionProps) => {
  const [checked, setChecked] = useState<boolean>(false);

  return (
    <div className={twJoin('relative flex items-center', className)}>
      <input
        checked={checked}
        className="peer opacity-0 absolute pointer-events-none"
        id={id}
        name={groupName}
        onChange={(event) => setChecked(event.target.checked)}
        type="radio"
      />
      <div className="absolute pointer-events-none left-4">
        <div className="relative w-5 h-5">
          <div className="absolute rounded-full border-md border-border-l1 w-full h-full" />
          <RadioIcon
            className={twJoin(
              'transition-opacity absolute fill-border-active',
              checked ? 'opacity-100' : 'opacity-0'
            )}
          />
        </div>
      </div>
      <label
        className="transition-colors w-full text-text-primary p-sm pl-12 gap-xs rounded-sm border-md border-border-l0 bg-surface-l1 peer-checked:font-medium peer-checked:border-border-active can-hover:hover:border-border-active"
        htmlFor={id}
      >
        {label}
      </label>
    </div>
  );
};

const RadioIcon = ({ className }: { className: string }) => (
  <svg
    className={className}
    fill="none"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M1.25 10C1.25 5.16751 5.16751 1.25 10 1.25C14.8325 1.25 18.75 5.16751 18.75 10C18.75 14.8325 14.8325 18.75 10 18.75C5.16751 18.75 1.25 14.8325 1.25 10ZM6.875 10C6.875 8.27411 8.27411 6.875 10 6.875C11.7259 6.875 13.125 8.27411 13.125 10C13.125 11.7259 11.7259 13.125 10 13.125C8.27411 13.125 6.875 11.7259 6.875 10Z"
      fill="inherit"
      fillRule="evenodd"
    />
  </svg>
);

export default RadioOption;
